import {
  Container,
  Flex,
  Center,
  Button,
  SimpleGrid,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  HStack,
  Text,
  Box,
  Spacer,
  Link,
  Image,
} from "@chakra-ui/react";

import React, { useEffect } from "react";

const EventsLanding: React.FC = () => {

  return (
    <>
      {/* <Flex> */}
      <Flex
        flexDirection="column"
        textAlign={{ base: "center", md: "center" }}
        justifyContent="center"
        minH={{ base: "60vh", md: "100vh" }}
        margin-bottom="10px"
        border-radius="8px" /* Rounded corners for the image */
        justify="center"
        align="center"
        position="relative"
        direction="column"
        flexGrow="1"
      >
        <Flex
          width="auto"
          justify="center"
          align="center"

        >
          <VStack>
            <Heading
              fontWeight="600"
              textAlign={{ base: "center", md: "left" }}
              color="black"
              size={{ base: "xl", md: "2xl" }}
              bg="transparent"
              margin="30px"
            >
              Join our first online masterclass on the 23 August
            </Heading>
            <img
              src="images/event.png"
              style={{
                maxWidth: '50%',   // Set the image to 50% of the container width
                height: 'auto',    // Maintain the aspect ratio
                borderRadius: '16px', // Rounded corners
                boxShadow: 'lg'
              }}
            />
            <Flex
              maxWidth="50%"
              align="flex-left"
            >
              <Text
                align="left"
                fontWeight="400"

              >
                <br></br>

                <br></br>
                Join us for an exciting online event that delves into the world of AI accelerated drug discovery with a focus on nucleic acid drugs. Discover how artificial intelligence is revolutionizing the process of developing new drugs, specifically those based on nucleic acids. Learn from Dianox' experts as they share their insights and experiences in this cutting-edge field. Don't miss out on this opportunity to explore the intersection of AI and drug discovery from the comfort of your own home!

              </Text>
            </Flex>
            <Flex
              direction={{ base: "column", md: "row" }}
              gap="6"
              justifyContent="flex-start"
              mt="0"
              px={{ base: "12", md: 0 }}
            >
              <Button
                colorScheme="blue"
                as="a"
                variant="outline"
                size="lg"
                href="https://www.eventbrite.com/e/ai-accelerated-drug-discovery-with-nucleic-acid-drugs-tickets-694811308327?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp&aff=ebdsshcopyurl"
                bg="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                border="none"
                _hover={{
                  bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                  border: "none",
                  filter: "brightness(125%)",
                }}
                _active={{
                  bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                  border: "none",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
                color="white"
              >
                Sign up for the event
              </Button>
            </Flex>
          </VStack>

        </Flex>
      </Flex >
    </>
  );
};

export default EventsLanding;
