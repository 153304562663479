import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Textarea,
  VStack,
  Text,
  chakra,
  FormErrorMessage,
  useToast,
  Container,
  Select,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ContactFormType } from "../../../interfaces/contact";
import ContactService from "../../../services/contact";

const schema = yup
  .object({
    firstName: yup.string().required("This is a required field"),
    lastName: yup.string().required("This is a required field"),
    jobTitle: yup.string().required("This is a required field"),
    company: yup.string().required("This is a required field"),
    email: yup.string().required("This is a required field"),
    phone: yup.string().nullable().notRequired(),
    message: yup.string().required("This is a required field"),
    subject: yup.string().required("This is a required field"),
  })
  .required();

const Contact: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<ContactFormType>({
    mode: "all",
    criteriaMode: "firstError",
  });

  const toast = useToast();
  const subject = watch("subject");

  const onSubmit = (data: ContactFormType) => {
    console.log(data);
    ContactService.formSubmit(data)
      .then((response) => {
        toast({
          title: (
            <Text fontWeight="normal">
              Thank you. Someone from Dianox will reach out to you shortly.
            </Text>
          ),

          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        reset();
      })
      .catch((err) => {
        toast({
          title: (
            <Text fontWeight="normal">
              Something went wrong. Please try again later.
            </Text>
          ),
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      });
  };

  return (
    <>
      <Box
        as={chakra.form}
        onSubmit={handleSubmit(onSubmit)}
        bg="white"
        pb="50"
      >
        <Flex
          direction="column"
          py="10"
          minH="70vh"
          id="contact"
          pt="40"
          zIndex="10"
          bg="white"
          gap="20"
        >
          <VStack w="full" spacing="3" px={{ base: "3", md: "0" }}>
            <Heading w="full" textAlign="center">
              Design a custom project
            </Heading>
            <Text w="full" fontSize="md" textAlign="center">
              Share your information and we will get in touch to discuss your project.
            </Text>
          </VStack>

          <Container maxW="container.md" px="10" centerContent>
            <Flex w="full" h="full" direction="column" gap="6">
              <Flex direction={{ base: "column", md: "row" }} w="full" gap="4">
                <FormControl isRequired isInvalid={Boolean(errors?.firstName)}>
                  <Input
                    type="text"
                    placeholder="First name"
                    {...register("firstName", { required: true })}
                    boxShadow="lg"
                    border="none"
                    _hover={{
                      border: "none",
                    }}
                    _active={{
                      border: "none",
                    }}
                    _focus={{
                      border: "none",
                    }}
                    _focusVisible={{
                      border: "none",
                    }}
                  />
                  <FormErrorMessage>
                    {errors?.firstName?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={Boolean(errors?.lastName)}>
                  <Input
                    type="text"
                    placeholder="Last name"
                    {...register("lastName", { required: true })}
                    boxShadow="lg"
                    border="none"
                    _hover={{
                      border: "none",
                    }}
                    _active={{
                      border: "none",
                    }}
                    _focus={{
                      border: "none",
                    }}
                    _focusVisible={{
                      border: "none",
                    }}
                  />
                  <FormErrorMessage>
                    {errors?.lastName?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex direction={{ base: "column", md: "row" }} w="full" gap="4">
                <FormControl isRequired isInvalid={Boolean(errors?.jobTitle)}>
                  <Input
                    type="text"
                    placeholder="Job title"
                    {...register("jobTitle", { required: true })}
                    border="none"
                    boxShadow="lg"
                    _hover={{
                      border: "none",
                    }}
                    _active={{
                      border: "none",
                    }}
                    _focus={{
                      border: "none",
                    }}
                    _focusVisible={{
                      border: "none",
                    }}
                  />
                  <FormErrorMessage>
                    {errors?.jobTitle?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={Boolean(errors?.company)}>
                  <Input
                    type="text"
                    placeholder="Organization"
                    {...register("company", { required: true })}
                    border="none"
                    boxShadow="lg"
                    _hover={{
                      border: "none",
                    }}
                    _active={{
                      border: "none",
                    }}
                    _focus={{
                      border: "none",
                    }}
                    _focusVisible={{
                      border: "none",
                    }}
                  />
                  <FormErrorMessage>
                    {errors?.company?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex direction={{ base: "column", md: "row" }} w="full" gap="4">
                <FormControl isRequired isInvalid={Boolean(errors?.email)}>
                  <Input
                    type="email"
                    placeholder="Email"
                    {...register("email", { required: true })}
                    border="none"
                    boxShadow="lg"
                    _hover={{
                      border: "none",
                    }}
                    _active={{
                      border: "none",
                    }}
                    _focus={{
                      border: "none",
                    }}
                    _focusVisible={{
                      border: "none",
                    }}
                  />
                  <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors?.phone)}>
                  <Input
                    type="text"
                    placeholder="Phone"
                    {...register("phone", { required: false })}
                    border="none"
                    boxShadow="lg"
                    _hover={{
                      border: "none",
                    }}
                    _active={{
                      border: "none",
                    }}
                    _focus={{
                      border: "none",
                    }}
                    _focusVisible={{
                      border: "none",
                    }}
                  />
                  <FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
              <FormControl isRequired isInvalid={Boolean(errors?.subject)}>
                <Select
                  placeholder="Select subject"
                  {...(subject === "" && { color: "#718096" })}
                  {...register("subject", { required: true })}
                  boxShadow="lg"
                  border="none"
                  _hover={{
                    border: "none",
                  }}
                  _active={{
                    border: "none",
                  }}
                  _focus={{
                    border: "none",
                  }}
                  _focusVisible={{
                    border: "none",
                  }}
                >
                  <option value="molecules">Custom aptamers</option>
                </Select>
                <FormErrorMessage>{errors?.subject?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={Boolean(errors?.message)}>
                <Textarea
                  placeholder="Message"
                  size="lg"
                  {...register("message", { required: true })}
                  border="none"
                  boxShadow="lg"
                  _hover={{
                    border: "none",
                  }}
                  _active={{
                    border: "none",
                  }}
                  _focus={{
                    border: "none",
                  }}
                  _focusVisible={{
                    border: "none",
                  }}
                />
                <FormErrorMessage>{errors?.message?.message}</FormErrorMessage>
              </FormControl>
              <Button
                variant="solid"
                colorScheme="cyan"
                size="lg"
                type="submit"
                bg="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                border="none"
                _hover={{
                  bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                  border: "none",
                  filter: "brightness(125%)",
                }}
                _active={{
                  bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                  border: "none",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
                color="white"
              >
                Send message
              </Button>
            </Flex>
          </Container>
        </Flex>
      </Box>
    </>
  );
};

export default Contact;
