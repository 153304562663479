import {
  Container,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
  Image,
  Link,
} from "@chakra-ui/react";
import React from "react";

const PublicationsContent: React.FC = () => {
  return (
    <Container maxW="container.xl">
      <Flex direction="column" w="full" gap="16">
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_15.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://www.biorxiv.org/content/10.1101/2023.11.24.568626v1"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                AptaBERT: Predicting aptamer binding interactions (2023)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_6.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://chemrxiv.org/engage/chemrxiv/article-details/64f5dcc479853bbd7815d9ec"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                ANALYTICAL DETERMINATION OF CORTISOL USING A FLUORESCENT 
                  APTAMER ASSAY (2023)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_6.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://chemrxiv.org/engage/chemrxiv/article-details/64206d37647e3dca998945bd"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                Synthesis of polydiacetylene (PDA) particles for 
                detection of histamine (2023)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_6.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://chemrxiv.org/engage/chemrxiv/article-details/6422fec5647e3dca99a78292"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                Using Saliva and LAMP for Non-invasive Detection of 
                Communicable Pathogens in Low Medical Access Regions (2023)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_1.png"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://www.mdpi.com/1422-0067/23/22/13866"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                Development of a Molecular Aptamer Beacon Applied to
                Magnetic-Assisted RNA Extraction for Detection of Dengue and
                Zika Viruses Using Clinical Samples (2022)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_13.png"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://www.frontiersin.org/articles/10.3389/fmicb.2022.976639/full"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                Spectral imaging and nucleic acid mimics fluorescence in situ hybridization (SI-NAM-FISH) for multiplex detection of clinical pathogens (2022)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_2.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
             <Link
                href="https://jnis.bmj.com/content/14/Suppl_1/A156.1"
                isExternal
               _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
              <Text fontSize="xl" fontWeight="400">
                E-149 Contrast labeled immunoliposomes for detecting rupture
                prone aneurysms (2022)
              </Text>
             </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_3.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            > 
              <Link
                href="https://www.sciencedirect.com/science/article/abs/pii/S1098882320300939"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                  Activation of phospholipase A2 by prostaglandin in vitro (2021)
                </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_5.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
            <Link
                href="https://www.sciencedirect.com/science/article/abs/pii/S002235492030602X"
                isExternal
              _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
              <Text fontSize="xl" fontWeight="400">
                The Use of Glycerol as an Enabling Excipient for
                Microwave-Induced In Situ Drug Amorphization (2021)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_7.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://www.cell.com/molecular-therapy-family/nucleic-acids/fulltext/S2162-2531(20)30325-5"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                Improved cancer targeting by multimerizing aptamers on nanoscaffolds (2020)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_8.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://www.sciencedirect.com/science/article/abs/pii/S0142961220305627"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                NickFect type of cell-penetrating peptides present enhanced efficiency for microRNA-146a delivery into dendritic cells and during skin inflammation (2020)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_9.jpeg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://academic.oup.com/nar/article/48/1/63/5637591"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                In vitro and in vivo properties of therapeutic oligonucleotides containing non-chiral 3′ and 5′ thiophosphate linkages (2020)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_4.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
            <Link
                href="https://www.sciencedirect.com/science/article/abs/pii/S0928098718304767"
                isExternal
              _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
              <Text fontSize="xl" fontWeight="400">
                Co-delivery of siRNA and etoposide to cancer cells using an MDEA
                esterquat based drug delivery system (2019)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_10.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://pubs.acs.org/doi/abs/10.1021/acs.jmedchem.8b01806"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                Improved RE31 analogues containing modified nucleic acid monomers: Thermodynamic, structural, and biological effects (2019)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_11.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://www.sciencedirect.com/science/article/pii/S0168365918307338"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                Fibrin-hyaluronic acid hydrogel-based delivery of antisense oligonucleotides for ADAMTS5 inhibition in co-delivered and resident joint cells in osteoarthritis (2019)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_12.jpg"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6525989/"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                A self-assembled, modular nucleic acid-based nanoscaffold for multivalent theranostic medicine (2019)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={4}>
            <Flex w="full" justify="center" align="center" h="full">
              <Image
                src="/images/publication_14.png"
                h="200px"
                borderRadius="5px"
                boxShadow="lg"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex
              w="full"
              justify="center"
              align="flex-start"
              h="full"
              direction="column"
              gap="8"
            >
              <Link
                href="https://www.nature.com/articles/s41598-019-42309-4"
                isExternal
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                <Text fontSize="xl" fontWeight="400">
                microRNA-155 inhibition restores Fibroblast Growth Factor 7 expression in diabetic skin and decreases wound inflammation (2019)
              </Text>
              </Link>
            </Flex>
          </GridItem>
        </SimpleGrid>
      </Flex>
    </Container>
  );
};

export default PublicationsContent;
