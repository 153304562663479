import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Heading,
  Th,
  Thead,
  Tr,
  Text,
  VStack,
  Container,
} from "@chakra-ui/react";
import React from "react";

const Pricing: React.FC = () => {
  return (
    <Flex
      direction="column"
      py="10"
      minH="70vh"
      id="codevelop"
      pt="40"
      zIndex="10"
      bg="white"
      gap="20"
    >
      <Container maxW="container.xl">
        <VStack w="full" spacing="3" pb="0" px={{ base: "3", md: "0" }}>
          <Heading w="full" textAlign="center">
            Co-develop with end-to-end drug discovery
          </Heading>
          <Text w="full" fontSize="md" textAlign="center">
            Incorporating big data and computational prediction in silico with
            in vitro drug design to revolutionize medicine.
          </Text>
        </VStack>
      </Container>
      <Container maxW="container.lg" centerContent>
        <TableContainer borderRadius="20" w="full">
          <Table
            variant="simple"
            bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
          >
            <Thead>
              <Tr>
                <Th
                  py="8"
                  color="white"
                  textAlign="center"
                  textTransform="none"
                  fontWeight="600"
                  fontSize="xl"
                >
                  In silico
                </Th>
                <Th
                  py="8"
                  color="white"
                  textAlign="center"
                  textTransform="none"
                  fontWeight="600"
                  fontSize="xl"
                >
                  In vitro
                </Th>
                <Th
                  py="8"
                  color="white"
                  textAlign="center"
                  textTransform="none"
                  fontWeight="600"
                  fontSize="xl"
                >
                  In vivo
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td py="8" color="white" textAlign="center" fontSize="xl">
                  Hit discovery
                </Td>
                <Td py="8" color="white" textAlign="center" fontSize="xl">
                  Hit-to-Lead
                </Td>
                <Td py="8" color="white" textAlign="center" fontSize="xl">
                  Lead optimization
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </Flex>
  );
};

export default Pricing;
