import {
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

const Obama: React.FC = () => {
  return (
    <Flex w="full">
      <Container maxW="container.lg" centerContent>
        <Flex
          w="full"
          direction="column"
          align="center"
          justify="center"
          gap="10"
        >
          <Image src="/images/obama-picture-min.jpg" alt="Innobooster Header" />
          <Heading>Dianox CEO meets President Obama</Heading>

          <Divider size="10px" color="black" />
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="10"
            >
              <Text w="full" textAlign="justify">
                Dianox CEO attended a roundtable discussion with President Obama
                on the future of health, sustainability and public policy during
                a town hall event in Berlin, Germany. The Obama Foundation
                hosted the town hall to engage emerging leaders from across
                Europe working in government, civil society, and the private
                sector.
              </Text>
              <Container
                maxW="container.md"
                borderLeft="2px solid"
                borderColor="cyan.500"
              >
                <Flex
                  w="full"
                  direction="column"
                  align="center"
                  justify="center"
                  gap="6"
                  pl="8"
                  position="relative"
                >
                  <Image
                    src="/images/quotes.png"
                    position="absolute"
                    top="-6"
                    left="0"
                  />
                  <Text
                    w="full"
                    textAlign="justify"
                    fontSize="xl"
                    fontWeight="600"
                  >
                    Meeting President Obama was one of the most profound
                    encounters of my life.
                  </Text>
                  <Text
                    w="full"
                    textAlign="justify"
                    fontSize="sm"
                    fontWeight="600"
                  >
                    Huram Konjen, CEO of Dianox
                  </Text>
                </Flex>
              </Container>
            </Flex>
          </Container>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/TOD7j59UBYk"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="14"
            >
              <Text w="full" textAlign="justify">
                "Meeting President Obama was one of the most profound encounters
                of my life. Especially as I got to share the room with emerging
                leaders from all over Europe. As President Obama came over to
                say hello, the first thing that went through my mind was "I hope
                he doesn't butcher my name." He did not disappoint. I listened
                to as many stories as I possibly could. It felt incredibly
                empowering, and even shaped the trajectory of Dianox."
                <br /> Huram Konjen
              </Text>{" "}
              <Container
                maxW="container.md"
                borderLeft="2px solid"
                borderColor="cyan.500"
              >
                <Flex
                  w="full"
                  direction="column"
                  align="center"
                  justify="center"
                  gap="6"
                  pl="8"
                  position="relative"
                >
                  <Image
                    src="/images/quotes.png"
                    position="absolute"
                    top="-6"
                    left="0"
                  />
                  <Text
                    w="full"
                    textAlign="justify"
                    fontSize="xl"
                    fontWeight="600"
                  >
                    I hope he doesn't butcher my name.
                  </Text>
                  <Text
                    w="full"
                    textAlign="justify"
                    fontSize="sm"
                    fontWeight="600"
                  >
                    Huram Konjen, CEO of Dianox
                  </Text>
                </Flex>
              </Container>
              <Text w="full" textAlign="justify">
                Following the town hall, secretariat members of the Obama
                Foundation introduced Dianox to key decision makers at the Bill
                & Melinda Gates Foundation. The objective for Dianox being to
                urge the Gates Foundation create a strategy and track for
                diagnostics.
              </Text>{" "}
            </Flex>
          </Container>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Obama;
