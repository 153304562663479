import {
  Button,
  Container,
  Flex,
  GridItem,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

const Nature: React.FC = () => {
  return (
    <Flex
      direction="column"
      py="10"
      minH="70vh"
      pt="40"
      zIndex="10"
      bg="white"
      gap="20"
      justify="center"
    >
      <Flex
        bgGradient="linear-gradient(98.65deg,#00535B 7.37%,#00C4D1 98.43%)"
        w="100vw"
        minH="100px"
        py="20"
      >
        <Container maxW="container.lg">
          <SimpleGrid columns={6}>
            <GridItem colSpan={{ base: 6, md: 2 }}>
              <Flex w="full" justify="center">
                <Image
                  src="/images/7706.png"
                  alt="nature"
                  h="300px"
                  borderRadius="5px"
                  boxShadow="lg"
                />
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 6, md: 4 }} mt={{ base: 4, md: 0 }}>
              <Flex
                direction="column"
                justify="center"
                gap="2"
                h="full"
                align="center"
                px={{ base: 3, md: 0 }}
              >
                <Heading
                  color="white"
                  w="full"
                  textAlign={{ base: "center", md: "left" }}
                >
                  Built on science
                </Heading>
                <Text
                  color="white"
                  w="full"
                  textAlign={{ base: "center", md: "left" }}
                >
                  The cost of developing a new drug, estimated at $2.6 billion,
                  is a significant challenge for scientists looking to create
                  effective treatments. Much of this cost is spent on
                  unsuccessful candidate therapies, resulting in a need for a
                  change in the drug discovery process. Leading
                  biopharmaceutical companies believe that AI offers a solution
                  to this problem.
                </Text>

                <Link
                  color="white"
                  href="https://www.nature.com/articles/d41586-018-05267-x"
                  isExternal
                  textDecoration="none"
                  _hover={{ textDecoration: "none", color: "#ebe9e8" }}
                  _active={{ textDecoration: "none", color: "#609ac3" }}
                  transition="0.15s"
                  mt="3"
                  w="full"
                  textAlign={{ base: "center", md: "left" }}
                >
                  <Text>
                    Nature: How artificial intelligence is changing drug
                    discovery
                  </Text>
                </Link>
                <Link
                  href="/publications"
                  mt="4"
                  mr={{ base: "0", lg: "auto" }}
                >
                  <Button
                    variant="ghost"
                    colorScheme="white"
                    border="1px solid"
                    borderColor="white"
                    color="white"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    Our publications
                  </Button>
                </Link>
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
    </Flex>
  );
};

export default Nature;
