import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import PublicationsContent from "../../components/publications/content";
import PublicationsHeader from "../../components/publications/header";

const Publications: React.FC = () => {
  return (
    <>
      <Box position="relative" pb="0" className="parent-container">
        <NavBar />
        <Flex w="full" direction="column" gap="8" pb="16">
          <PublicationsHeader />
          <PublicationsContent />
        </Flex>

        <Footer />
      </Box>
    </>
  );
};

export default Publications;
