import {
  Container,
  Flex,
  GridItem,
  SimpleGrid,
  Image,
  Text,
  Box,
} from "@chakra-ui/react";
import React from "react";

const ScienceUnlock: React.FC = () => {
  return (
    <>
      <Flex
        w="full"
        justify="center"
        align="center"
        h={{ base: "auto", lg: "40vh" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 1, md: 1 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="2xl" fontWeight="600">
                  Reverse engineering from protein to RNA & DNA
                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    A key problem with nucleic acid drugs is how they are
                    designed. Scientists step in the wet lab with a list of
                    sequences and no additional data. Hoping one sequence binds
                    to the target. Even if they find that seqeuence, they do not
                    know if it also binds to other molecules.
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 0, md: 2 }}>
              <Flex justify="center" align="center">
                <Image src="/images/microscope.svg" alt="test-tube" />
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
    </>
  );
};

export default ScienceUnlock;
