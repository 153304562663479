import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import DanishChamberOfCommerce from "../../components/news/danish-chamber-of-commerce";

const DanishChamberOfCommercePage: React.FC = () => {
  return (
    <>
      <Box position="relative" pb="0" className="parent-container">
        <Flex w="full" direction="column" gap="0" pb="16">
          <NavBar />
          <DanishChamberOfCommerce />
        </Flex>
        <Footer />
      </Box>
    </>
  );
};

export default DanishChamberOfCommercePage;
