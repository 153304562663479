import {
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

const Righospitalet: React.FC = () => {
  return (
    <Flex w="full">
      <Container maxW="container.lg" centerContent>
        <Flex
          w="full"
          direction="column"
          align="center"
          justify="center"
          gap="10"
        >
          <Image src="/images/riget-croped.jpg" alt="Innobooster Header" />
          <Heading>Partnership with Rigshospitalet</Heading>

          <Divider size="10px" color="black" />
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="10"
            >
              <Text w="full" textAlign="justify">
                Dianox teams up with the Department of Molecular Medicine from
                Rigshospitalet for assay development of testing device.
              </Text>{" "}
              <Text w="full" textAlign="justify">
                This research collaboration presents a great opportunity for
                synergy. Rigshospitalet's experience in assay development
                complements Dianox's expertise in designing devices. Dianox will
                allocate an R&D Scientist to work at the Molecular Medicine
                laboratory to fully utilize the depth of experience available at
                Rigshospitalet.
              </Text>
            </Flex>
          </Container>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/m8WheaQcb3s"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="6"
            >
              <Text w="full" textAlign="justify">
                Clinical Professor Peter Garred leads the group of 10
                researchers at Rigshospitalet, and for the past 20 years he has
                investigated molecular genetics of the complement system in
                general and the lectin pathway in particular. Peter has years of
                experience in leading research and education at a high level
                with insights into translational clinical research. He is also
                connected with a large network of scientists and clinicians at
                local, national and international levels.
              </Text>{" "}
            </Flex>
          </Container>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Righospitalet;
