import {
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

const Shenzen: React.FC = () => {
  return (
    <Flex w="full">
      <Container maxW="container.lg" centerContent>
        <Flex
          w="full"
          direction="column"
          align="center"
          justify="center"
          gap="10"
        >
          <Image
            src="/images/CEO%20guest%20speaker%20at%20SDG%20Lab%20in%20China%20Nov%202019%20working%20on%20diagnostics-1-cropped-min.jpg"
            alt="Innobooster Header"
          />
          <Heading>SDG accelerator in Shenzhen</Heading>
          <Divider size="10px" color="black" />
          <Heading size="md">
            Dianox CEO invited to present to 1,500+ people in Shenzhen
          </Heading>
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="10"
            >
              <Text w="full" textAlign="justify">
                In November 2019 right before the new coronavirus outbreak,
                Dianox attended the world's biggest SDG hackathon in Shenzhen,
                China.
              </Text>{" "}
              <Text w="full" textAlign="justify">
                Huram Konjen was invited to speak at UNLEASH Innovation Lab for
                SDGs in front of 1.500+ guests in Shenzhen, China. Participants
                included nobel laureates Muhammad Yunus, Grammy awarded musician
                Peter Himmelman, as well as a number of experts from World
                Health Organization, United Nations, Gates Foundation and many
                more.
              </Text>
            </Flex>
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="0"
            >
              <Image src="/images/ceo-diagnostics-3-min.jpg" />
              <Text w="full" fontSize="xs">
                CEO of Dianox presenting on stage
              </Text>{" "}
            </Flex>
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="10"
            >
              <Text w="full" textAlign="justify">
                Originally, Huram was invited to participate in a roundtable
                discussion on global health innovation with the chairman of
                Beijing Genomics Institute (BGI), Wang Jian. Unfortunately, due
                to scheduling conflicts the BGI chairman had to cancel.
              </Text>{" "}
              <Text w="full" textAlign="justify">
                "Having participated in UNLEASH in 2018 in Singapore, I was
                asked to share insights on how you can leverage knowledge from
                all the experts at the hackathon. But I wasn't a fan of that
                word: Leverage. It just sounded calculating. As if you're
                supposed to 'get something' from every exchange. But I learned
                that you can if you leave the expert hat at home, and instead
                approach every conversation with humble curiosity. It's the best
                pathway to meaningful connection and that's how you leverage
                knowledge from experts." <br />
                Huram Konjen
              </Text>
            </Flex>
          </Container>
          <Flex
            w="full"
            direction="column"
            align="center"
            justify="center"
            gap="0"
          >
            <Flex direction={{ base: "column", lg: "row" }} w="full" gap="2">
              <Image
                src="/images/CEO-diagnostics-5-min.jpg"
                objectFit="contain"
                w="500px"
              />
              <Image
                src="/images/CEO-diagnostics-2-min.jpg"
                objectFit="contain"
                w="500px"
              />
            </Flex>
            <Text w="full" fontSize="xs">
              CEO of Dianox attended UNLEASH 2019
            </Text>{" "}
          </Flex>
          <Container
            maxW="container.md"
            borderLeft="2px solid"
            borderColor="cyan.500"
            my="20"
            h="200px"
          >
            <Flex
              w="full"
              direction="column"
              align="center"
              h="full"
              justify="center"
              gap="6"
              pl="8"
              position="relative"
            >
              <Image
                src="/images/quotes.png"
                position="absolute"
                top="50"
                left="0"
              />
              <Text w="full" textAlign="justify" fontSize="xl" fontWeight="600">
                Everything is double the speed in Shenzhen.
              </Text>
            </Flex>
          </Container>
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="10"
            >
              <Text w="full" textAlign="justify">
                There's a saying in the region that goes 'everything's double
                the speed in Shenzhen,' which is a great description of the
                fast-paced environment at UNLEASH. While the Dianox team was in
                Shenzhen, they also participated in the UNLEASH+ accelerator
                program and went on to partner with medical professionals in
                Africa and Southeast Asia.
              </Text>{" "}
            </Flex>
          </Container>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Shenzen;
