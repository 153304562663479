import { Flex, Container, Box, VStack, Text, Heading } from "@chakra-ui/react";
import React from "react";

const Proof: React.FC = () => {
  return (
    <Box
      w="full"
      h="full"
      bgGradient="linear-gradient(98.65deg,#00535B 7.37%,#00C4D1 98.43%)"
      py="8"
    >
      <Container maxW="container.xl">
        <Flex
          direction={{ base: "column", md: "row" }}
          gap="10"
          justifyContent="space-between"
          align="center"
        >
          <VStack spacing="1">
            <Heading w="full" textAlign="center" color="white">
              Up to 10<Text as="sup">60</Text>
            </Heading>
            <Text w="full" fontSize="md" textAlign="center" color="white">
              compounds per target
            </Text>
          </VStack>
          <VStack spacing="1">
            <Heading w="full" textAlign="center" color="white">
              200K
            </Heading>
            <Text w="full" fontSize="md" textAlign="center" color="white">
              high value targets
            </Text>
          </VStack>
          <VStack spacing="1">
            <Heading w="full" textAlign="center" color="white">
              2,500
            </Heading>
            <Text w="full" fontSize="md" textAlign="center" color="white">
              lead opportunities
            </Text>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Proof;
