import {
  Container,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  HStack,
  Text,
  Box,
  Link,
} from "@chakra-ui/react";

const Molecules: React.FC = () => {
  return (
    <Flex
      direction="column"
      py="10"
      minH="70vh"
      id="pipeline"
      pt="80px"
      pb="80px"
      zIndex="10"
      bgGradient="linear-gradient(98.65deg,#00535B 7.37%,#00C4D1 98.43%)"
      gap="10"
      justify="center"
      align="center"
    >
      <VStack spacing="3" px={{ base: "3", md: "0" }}>
        <Heading w="full" textAlign="center" color="white">
          Our pipeline of therapeutics
        </Heading>
        <Text w="full" fontSize="md" textAlign="center" color="white">
          <Link
            color="white"
            href="#contact"
            textDecoration="none"
            _hover={{ textDecoration: "none", color: "#ebe9e8" }}
            _active={{ textDecoration: "none", color: "#609ac3" }}
            transition="0.15s"
            mt="3"
          >
            Reach out
          </Link>{" "}
          to license our proprietary assets.
        </Text>
        <Text w="full" fontSize="md" textAlign="center" color="white"></Text>
      </VStack>
      <Container maxW="container.lg" centerContent>
        <TableContainer borderRadius="20" w="full" bg="white" boxShadow="lg">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th
                  py="8"
                  color="#595959"
                  textAlign="left"
                  textTransform="none"
                  fontWeight="600"
                  fontSize="sm"
                  w="100px"
                >
                  Area
                </Th>
                <Th
                  py="8"
                  color="#595959"
                  textAlign="left"
                  textTransform="none"
                  fontWeight="600"
                  fontSize="sm"
                  w="100px"
                >
                  Target
                </Th>
                <Th
                  py="8"
                  color="#595959"
                  textAlign="left"
                  textTransform="none"
                  fontWeight="600"
                  fontSize="sm"
                  w="200px"
                >
                  <HStack spacing="40" align="flex-start">
                    <Text fontSize="sm" color="#595959" textAlign="left">
                      Discovery
                    </Text>
                    <Text fontSize="sm" color="#595959">
                      Optimization
                    </Text>
                    <Text fontSize="sm" color="#595959">
                      Preclinical
                    </Text>
                  </HStack>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td
                  textAlign="left"
                  fontSize="sm"
                  color="#595959"
                  borderBottom="1px solid"
                  borderColor="gray.100"
                >
                  Oncology
                </Td>
                <Td textAlign="left" fontSize="sm" color="#595959">
                  <VStack spacing="3" align="flex-start">
                    <Text fontSize="sm" color="#595959" textAlign="left">
                      Breast Cancer
                    </Text>
                    <Text fontSize="sm" color="#595959">
                      Melanoma
                    </Text>
                    <Text fontSize="sm" color="#595959">
                      HCC
                    </Text>
                  </VStack>
                </Td>
                <Td
                  color="white"
                  textAlign="center"
                  fontSize="xl"
                  borderLeft="1px solid"
                  borderLeftColor="gray.100"
                  px="0"
                >
                  <VStack spacing="3" align="flex-start">
                    <Box
                      w="610px"
                      bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                      height="20px"
                      borderTopRightRadius="10px"
                      borderBottomRightRadius="10px"
                    ></Box>
                    <Box
                      w="410px"
                      bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                      height="20px"
                      borderTopRightRadius="10px"
                      borderBottomRightRadius="10px"
                    ></Box>
                    <Box
                      w="155px"
                      bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                      height="20px"
                      borderTopRightRadius="10px"
                      borderBottomRightRadius="10px"
                    ></Box>
                  </VStack>
                </Td>
              </Tr>
              <Tr>
                <Td textAlign="left" fontSize="sm" color="#595959">
                  Immunotherapy
                </Td>
                <Td textAlign="left" fontSize="sm" color="#595959">
                  <VStack spacing="3" align="flex-start">
                    <Text fontSize="sm" color="#595959">
                      Cytokine Mod
                    </Text>
                    <Text fontSize="sm" color="#595959">
                      Ab Regulation
                    </Text>
                    <Text fontSize="sm" color="#595959">
                      Innate Imm Mod
                    </Text>
                    <Text fontSize="sm" color="#595959">
                      Imm Tolerance Ind
                    </Text>
                  </VStack>
                </Td>
                <Td
                  color="white"
                  textAlign="center"
                  fontSize="xl"
                  borderLeft="1px solid"
                  borderLeftColor="gray.100"
                  px="0"
                >
                  <VStack spacing="3" align="flex-start">
                    <Box
                      w="310px"
                      bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                      height="20px"
                      borderTopRightRadius="10px"
                      borderBottomRightRadius="10px"
                    ></Box>
                    <Box
                      w="155px"
                      bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                      height="20px"
                      borderTopRightRadius="10px"
                      borderBottomRightRadius="10px"
                    ></Box>
                    <Box
                      w="155px"
                      bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                      height="20px"
                      borderTopRightRadius="10px"
                      borderBottomRightRadius="10px"
                    ></Box>
                    <Box
                      w="155px"
                      bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                      height="20px"
                      borderTopRightRadius="10px"
                      borderBottomRightRadius="10px"
                    ></Box>
                  </VStack>
                </Td>
              </Tr>
              <Tr>
                <Td textAlign="left" fontSize="sm" color="#595959">
                  Dermatology
                </Td>
                <Td textAlign="left" fontSize="sm" color="#595959">
                  <VStack spacing="3" align="flex-start">
                    <Text fontSize="sm" color="#595959">
                      Autoimmune Blist
                    </Text>
                    <Text fontSize="sm" color="#595959">
                      Alopecia
                    </Text>
                    <Text fontSize="sm" color="#595959">
                      Lichen Pl
                    </Text>
                  </VStack>
                </Td>
                <Td
                  color="white"
                  textAlign="center"
                  fontSize="xl"
                  borderLeft="1px solid"
                  borderLeftColor="gray.100"
                  px="0"
                >
                  <VStack spacing="3" align="flex-start">
                    <Box
                      w="155px"
                      bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                      height="20px"
                      borderTopRightRadius="10px"
                      borderBottomRightRadius="10px"
                    ></Box>
                    <Box
                      w="155px"
                      bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                      height="20px"
                      borderTopRightRadius="10px"
                      borderBottomRightRadius="10px"
                    ></Box>
                    <Box
                      w="155px"
                      bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                      height="20px"
                      borderTopRightRadius="10px"
                      borderBottomRightRadius="10px"
                    ></Box>
                  </VStack>
                </Td>
              </Tr>
              <Tr>
                <Td textAlign="left" fontSize="sm" color="#595959">
                  Ophthalmology
                </Td>
                <Td textAlign="left" fontSize="sm" color="#595959">
                  <VStack spacing="3" align="flex-start">
                    <Text fontSize="sm" color="#595959" textAlign="left">
                      Eye melanoma
                    </Text>
                  </VStack>
                </Td>
                <Td
                  color="white"
                  textAlign="center"
                  fontSize="xl"
                  borderLeft="1px solid"
                  borderLeftColor="gray.100"
                  px="0"
                >
                  <VStack spacing="3" align="flex-start">
                    <Box
                      w="155px"
                      bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                      height="20px"
                      borderTopRightRadius="10px"
                      borderBottomRightRadius="10px"
                    ></Box>
                  </VStack>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </Flex>
  );
};

export default Molecules;
