import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { Box } from "@chakra-ui/react";
import CookiePolicy from "./components/cookie-policy";

const App: React.FC = () => {
  return (
    <Box minH="100vh" minW="100vw" position="relative">
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <CookiePolicy />
    </Box>
  );
};

export default App;
