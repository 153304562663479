import { Container, Flex, Text } from "@chakra-ui/react";
import React from "react";

const InvestContent: React.FC = () => {
  return (
    <Container centerContent maxW="container.xl" pt="20">
      <Flex direction="column" w="full" px="18" gap="5">
        <Text w="full" textAlign="left" fontSize="2xl" fontWeight="600" py="8">
          A message from our founders
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          Dear shareholders
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          Biology has been the foundation of human life for centuries. From the food we eat to the air we breathe, everything is interconnected with the science of biology. The field of biotechnology aims to harness this power and use it to improve human life in unimaginable ways. Biotechnology involves the manipulation of living organisms or their parts to produce useful products.
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          One of the most significant contributions of biotechnology has been in the field of medicine. Advances in biotechnology have led to the development of life-saving drugs, vaccines, and therapies for a range of diseases. Biotechnology has made it possible to create personalized treatments that target specific genetic mutations, increasing the effectiveness of treatments while minimizing side effects.
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          The history of biotechnology is a testament to the power of life and the ingenuity of humans. For thousands of years, we have used our knowledge of biology to heal ourselves and create new products. Today, we stand on the threshold of a new era in biotechnology that promises to revolutionize our world again.
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          At the forefront of this revolution is the convergence of artificial intelligence and bioinformatics. Bioinformatics is the field of science that combines biology, computer science, and statistics to analyze and interpret biological data. AI is the field of computer science that focuses on creating intelligent machines that can perform tasks that usually require human intelligence, such as decision-making and learning.
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          In 1956, John McCarthy coined the term "artificial intelligence" (AI), marking the beginning of a new era in computing. His vision was to create machines that could reason, learn, and adapt like human beings. While the idea of intelligent machines had been around for centuries, McCarthy's proposal marked the first time that scientists and researchers began to take the concept seriously. Over the next few decades, researchers made rapid progress in the field, developing new algorithms and techniques that could solve increasingly complex problems. Today, the field of AI continues to evolve and expand, with new breakthroughs and applications emerging all the time.
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          In the 1970s, the field of biotechnology emerged, fueled by breakthroughs in molecular biology and genetics. Biotechnology offered a new approach to understanding and manipulating living organisms, with the potential to revolutionize medicine. Scientists began using genetic engineering techniques to create new organisms with specific traits, such as bacteria that produced insulin. As the field advanced, new techniques such as gene editing and synthetic biology opened up even more possibilities for creating novel organisms and materials.
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          In recent years, a new type of AI has emerged: the language model. Large language models (LLMs) are transforming drug discovery by enabling faster and more accurate analysis of vast amounts of scientific literature and experimental data. With their ability to understand human-like text, LLMs can help us uncover hidden patterns and insights that might otherwise go unnoticed. By training language models on large datasets of chemical and biological information, we develop predictive models that can identify potential drug candidates and predict their efficacy. As the field of drug discovery continues to evolve, language models will play an increasingly important role in accelerating the development of new and innovative treatments for a wide range of diseases.
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          We believe the combination of biotechnology and AI is a powerful force that will transform drug development and improve human health in unimaginable ways. Much like the dynamic duo of Watson and Crick, who together discovered the structure of DNA, biotechnology and AI will revolutionize medicine. While there are challenges to be addressed, the potential benefits of this complex intersection are immense. As we continue to explore the possibilities of biotech and AI, we can create a more sustainable, healthier, and prosperous future for ourselves and future generations.
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          Of course, as with any new technology, there are also potential risks. We must ensure that we are using this technology ethically and responsibly, and that we are not creating unintended consequences. Following the spirit of Watson and Crick's groundbreaking discovery, we must remain committed to responsible scientific inquiry and innovation.
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          Despite these challenges, we believe that the future of biotechnology is bright, and we are excited to be at the forefront of this revolution. At our company, we are dedicated to advancing biotechnology through responsible use of AI, and we look forward to working with you to build a better world for all.
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          Dream big, science bigger
        </Text>
        <Text w="full" textAlign="left" fontSize="md" fontWeight="400">
          The founders
        </Text>
      </Flex>
    </Container>
  );
};

export default InvestContent;
