import {
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

const Innobooster: React.FC = () => {
  return (
    <Flex w="full">
      <Container maxW="container.lg" centerContent>
        <Flex
          w="full"
          direction="column"
          align="center"
          justify="center"
          gap="6"
        >
          <Image
            src="/images/innobooster-header.png"
            alt="Innobooster Header"
          />
          <Heading>€140,000 Innobooster granted</Heading>

          <Divider size="10px" color="black" />
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="6"
            >
              <Text w="full" textAlign="justify">
                Innovation Fund Denmark invested in Dianox for the second time.
                €66,000 Innobooster grant received to expand our diagnostic
                platform to include more tests.
              </Text>{" "}
              <Text w="full" textAlign="justify">
                The fund previously granted €61,000 to Dianox to develop a
                diagnostic biotechnology platform. Bringing the total investment
                from the Fund up to €140,000.
              </Text>
              <Text w="full" textAlign="justify">
                A photo reel of our CTO and Regulatory Specialist reacting to
                the good news.
              </Text>
            </Flex>
          </Container>
          <Flex direction={{ base: "column", lg: "row" }} w="full" gap="6">
            <VStack w="full">
              <Image src="/images/innobooster-1.png" alt="innobooster-1" />
              <Text fontSize="sm" color="gray.600">
                CEO grabs their attention to share an update.
              </Text>
            </VStack>
            <VStack w="full">
              <Image src="/images/innobooster-2.png" alt="innobooster-1" />
              <Text fontSize="sm" color="gray.600">
                They listen carefully optimistic.
              </Text>
            </VStack>
            <VStack w="full">
              <Image src="/images/innobooster-3.png" alt="innobooster-1" />
              <Text fontSize="sm" color="gray.600">
                Both burst into joy upon hearing the news.
              </Text>
            </VStack>
          </Flex>
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="6"
            >
              <Text w="full" textAlign="justify">
                Innovation Fund Denmark supports excellent research and
                innovation projects across the country. For more information
                visit innovationsfonden.dk.
              </Text>{" "}
            </Flex>
          </Container>
          <Image
            src="/images/innovationfund-min.png"
            alt="Innobooster Header"
          />
        </Flex>
      </Container>
    </Flex>
  );
};

export default Innobooster;
