import {
  Container,
  Flex,
  Center,
  Button,
  SimpleGrid,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  HStack,
  Text,
  Box,
  Spacer,
  Link,
  Image,
} from "@chakra-ui/react";

import React, { useEffect } from "react";

const CustomDevelopmentLanding: React.FC = () => {
  // useEffect(() => {
  //   document.querySelector("video")!.playbackRate = 1;
  // }, []);

  return (
    <>
      <Box minH={{ base: "60vh", md: "100vh" }}>
        <img src="images/apt6.png" style={{ position: 'absolute' }} />

        <SimpleGrid
          columns={[1, 2]}
          spacing={10}
          px={{ base: "1", md: "24" }}
          bg="transparent"
        >
          <Flex
            h="full"
            w="100%"
            direction="column"
            gap="5"
            justifyContent="flex-end"
            py="10"
            pt={{ base: "8", md: "40" }}
            px={{ base: "1", md: "5" }}
            bg="transparent"
          >
            <Flex direction="column" w="full" gap="5" bg="transparent">
              <Flex
                direction="column"
                w="full"
                gap={{ base: "3", md: "8" }}
                pt="10"
                display={{ base: "flex", md: "flex" }}
                bg="transparent"
              >
                <Heading
                  fontWeight="600"
                  textAlign={{ base: "center", md: "left" }}
                  color="black"
                  size={{ base: "xl", md: "2xl" }}
                  w="full"
                  bg="transparent"
                >
                  Molecules tailored
                </Heading>
                <Heading
                  fontWeight="600"
                  textAlign={{ base: "center", md: "left" }}
                  color="black"
                  size={{ base: "xl", md: "2xl" }}
                  bg="transparent"
                >
                  to your needs
                </Heading>
              </Flex>
            </Flex>

            <Flex
              h="full"
              w="full"
              direction="column"
              gap={{ base: "1", md: "3" }}
              justifyContent="flex-start"
              py="0"
              bg="transparent"
              color="black"
              fontWeight="400"
              fontSize="lg"
            >
              Aptamers developed for your target using cutting-edge technology.
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              gap="6"
              justifyContent="flex-start"
              mt="0"
              px={{ base: "12", md: 0 }}
            >
              <Button
                colorScheme="blue"
                variant="outline"
                size="lg"
                onClick={() => {
                  document.getElementById("contact")!.scrollIntoView();
                }}
                bg="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                border="none"
                _hover={{
                  bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                  border: "none",
                  filter: "brightness(125%)",
                }}
                _active={{
                  bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                  border: "none",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
                color="white"
              >
                Custom aptamers
              </Button>

              <Button
                as="a"
                href="mailto:order@dianox.com?Subject=Aptamer%20library"
                size="lg"
                variant="outline"
                bg="transparent"
                border="1px solid"
                borderColor="#EB6280"
                transition="0.15s"
                color="#EB6280"
                _hover={{
                  bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                  border: "none",
                  color: "white",
                }}
                _active={{
                  bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                  border: "none",
                  color: "white",
                  filter: "brightness(75%)",
                }}
              >
                Speak with us
              </Button>
            </Flex>
          </Flex>

        </SimpleGrid>
      </Box >
    </>
  );
};

export default CustomDevelopmentLanding;
