import {
  Box,
  Container,
  GridItem,
  Heading,
  Link,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";

const NewsCards: React.FC = () => {
  return (
    <>
      <Container maxW="container.xl" pb="26">
        <SimpleGrid columns={12} spacing="8">
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <Link href="/news/innobooster-granted">
              <Box
                bgImage="url('/images/innobooster.jpg')"
                bgSize="cover"
                backgroundPosition="center"
                w="full"
                h="220px"
              >
                <Box
                  w="full"
                  h="full"
                  bg="rgba(0,0,0,0.4)"
                  _hover={{
                    bgGradient:
                      "linear-gradient(180deg,rgba(106,219,255,.2),rgba(106,255,192,.2)),linear-gradient(1turn,rgba(0,0,0,.5),transparent 57.54%);",
                    boxShadow: "4px 6px 8px hsl(0deg 0% 48% / 30%)",
                  }}
                  _active={{
                    bgGradient:
                      "linear-gradient(180deg,rgba(106,219,255,.2),rgba(106,255,192,.2)),linear-gradient(1turn,rgba(0,0,0,.5),transparent 80.54%);",
                    boxShadow: "4px 6px 8px hsl(0deg 0% 48% / 30%)",
                  }}
                  position="relative"
                >
                  <Heading
                    color="white"
                    fontSize="2xl"
                    position="absolute"
                    bottom="5"
                    left="5"
                  >
                    €140,000 Innobooster
                  </Heading>
                </Box>
              </Box>
            </Link>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <Link href="/news/partnership-with-rigshospitalet">
              <Box
                bgImage="url('/images/riget.jpg')"
                bgSize="cover"
                backgroundPosition="center"
                w="full"
                h="220px"
                bgColor="black"
                borderRadius="8"
              >
                <Box
                  w="full"
                  h="full"
                  bg="rgba(0,0,0,0.4)"
                  _hover={{
                    bgGradient:
                      "linear-gradient(180deg,rgba(106,219,255,.2),rgba(106,255,192,.2)),linear-gradient(1turn,rgba(0,0,0,.5),transparent 57.54%);",
                    boxShadow: "4px 6px 8px hsl(0deg 0% 48% / 30%)",
                  }}
                  position="relative"
                  _active={{
                    bgGradient:
                      "linear-gradient(180deg,rgba(106,219,255,.2),rgba(106,255,192,.2)),linear-gradient(1turn,rgba(0,0,0,.5),transparent 80.54%);",
                    boxShadow: "4px 6px 8px hsl(0deg 0% 48% / 30%)",
                  }}
                >
                  <Heading
                    color="white"
                    fontSize="2xl"
                    position="absolute"
                    bottom="5"
                    left="5"
                  >
                    Partnership with hospital
                  </Heading>
                </Box>
              </Box>
            </Link>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <Link href="/news/dianox-meets-president-obama">
              <Box
                bgImage="url('/images/obama.jpg')"
                bgSize="cover"
                backgroundPosition="center"
                w="full"
                h="220px"
                bgColor="black"
                borderRadius="8"
              >
                <Box
                  w="full"
                  h="full"
                  bg="rgba(0,0,0,0.4)"
                  _hover={{
                    bgGradient:
                      "linear-gradient(180deg,rgba(106,219,255,.2),rgba(106,255,192,.2)),linear-gradient(1turn,rgba(0,0,0,.5),transparent 57.54%);",
                    boxShadow: "4px 6px 8px hsl(0deg 0% 48% / 30%)",
                  }}
                  _active={{
                    bgGradient:
                      "linear-gradient(180deg,rgba(106,219,255,.2),rgba(106,255,192,.2)),linear-gradient(1turn,rgba(0,0,0,.5),transparent 80.54%);",
                    boxShadow: "4px 6px 8px hsl(0deg 0% 48% / 30%)",
                  }}
                  position="relative"
                >
                  <Heading
                    color="white"
                    fontSize="2xl"
                    position="absolute"
                    bottom="5"
                    left="5"
                  >
                    Dianox CEO meets Obama
                  </Heading>
                </Box>
              </Box>
            </Link>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <Link href="/news/eu-fund">
              <Box
                bgImage="url('/images/eu-fund.jpg')"
                bgSize="cover"
                backgroundPosition="center"
                w="full"
                h="220px"
                bgColor="black"
                borderRadius="8"
              >
                <Box
                  w="full"
                  h="full"
                  bg="rgba(0,0,0,0.4)"
                  _hover={{
                    bgGradient:
                      "linear-gradient(180deg,rgba(106,219,255,.2),rgba(106,255,192,.2)),linear-gradient(1turn,rgba(0,0,0,.5),transparent 57.54%);",
                    boxShadow: "4px 6px 8px hsl(0deg 0% 48% / 30%)",
                  }}
                  _active={{
                    bgGradient:
                      "linear-gradient(180deg,rgba(106,219,255,.2),rgba(106,255,192,.2)),linear-gradient(1turn,rgba(0,0,0,.5),transparent 80.54%);",
                    boxShadow: "4px 6px 8px hsl(0deg 0% 48% / 30%)",
                  }}
                  position="relative"
                >
                  <Heading
                    color="white"
                    fontSize="2xl"
                    position="absolute"
                    bottom="5"
                    left="5"
                  >
                    €45,000 awarded by EU Fund
                  </Heading>
                </Box>
              </Box>
            </Link>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <Link href="/news/interviewed-by-danish-chamber-of-commerce">
              <Box
                bgImage="url('/images/dansk.jpg')"
                bgSize="cover"
                backgroundPosition="center"
                w="full"
                h="220px"
                bgColor="black"
                borderRadius="8"
              >
                <Box
                  w="full"
                  h="full"
                  bg="rgba(0,0,0,0.4)"
                  _hover={{
                    bgGradient:
                      "linear-gradient(180deg,rgba(106,219,255,.2),rgba(106,255,192,.2)),linear-gradient(1turn,rgba(0,0,0,.5),transparent 57.54%);",
                    boxShadow: "4px 6px 8px hsl(0deg 0% 48% / 30%)",
                  }}
                  _active={{
                    bgGradient:
                      "linear-gradient(180deg,rgba(106,219,255,.2),rgba(106,255,192,.2)),linear-gradient(1turn,rgba(0,0,0,.5),transparent 80.54%);",
                    boxShadow: "4px 6px 8px hsl(0deg 0% 48% / 30%)",
                  }}
                  position="relative"
                >
                  <Heading
                    color="white"
                    fontSize="2xl"
                    position="absolute"
                    bottom="5"
                    left="5"
                  >
                    Interview by Dansk Erhverv
                  </Heading>
                </Box>
              </Box>
            </Link>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <Link href="/news/sdg-accelerator-shenzhen">
              <Box
                bgImage="url('/images/shenzen.png')"
                bgSize="cover"
                backgroundPosition="center"
                w="full"
                h="220px"
                bgColor="black"
                borderRadius="8"
              >
                <Box
                  w="full"
                  h="full"
                  bg="rgba(0,0,0,0.4)"
                  _hover={{
                    bgGradient:
                      "linear-gradient(180deg,rgba(106,219,255,.2),rgba(106,255,192,.2)),linear-gradient(1turn,rgba(0,0,0,.5),transparent 57.54%);",
                    boxShadow: "4px 6px 8px hsl(0deg 0% 48% / 30%)",
                  }}
                  _active={{
                    bgGradient:
                      "linear-gradient(180deg,rgba(106,219,255,.2),rgba(106,255,192,.2)),linear-gradient(1turn,rgba(0,0,0,.5),transparent 80.54%);",
                    boxShadow: "4px 6px 8px hsl(0deg 0% 48% / 30%)",
                  }}
                  position="relative"
                >
                  <Heading
                    color="white"
                    fontSize="2xl"
                    position="absolute"
                    bottom="5"
                    left="5"
                  >
                    SDG accelerator in Shenzhen
                  </Heading>
                </Box>
              </Box>
            </Link>
          </GridItem>
        </SimpleGrid>
      </Container>
    </>
  );
};

export default NewsCards;
