const pointersData = [
  {
    color: "#2795D5",
    name: "Dermatology",
    content:
      "Dermatology is the branch of medicine that specializes in skin, hair, and nail conditions. This therapeutic area encompasses a wide range of conditions, from common issues like acne and eczema to more complex disorders like skin cancer and autoimmune diseases. Dermatologists utilize various treatment options, including topical medications, oral drugs, and innovative procedures such as laser therapy and photodynamic therapy, to manage and improve the health of patients' skin.",
  },
  {
    color: "#5681C2",
    name: "Endocrinology",
    content:
      "Endocrinology focuses on the study and treatment of hormonal imbalances and disorders within the endocrine system. This therapeutic area addresses conditions affecting glands such as the pituitary, thyroid, adrenal, and pancreas. Endocrinologists treat a variety of issues, including diabetes, thyroid disorders, osteoporosis, and hormone-related cancers. Treatment options may involve hormone replacement therapy, lifestyle changes, and medication management.",
  },
  {
    color: "#9266AA",
    name: "Hematology",
    content:
      "Hematology is the study and treatment of blood-related diseases and disorders, encompassing blood cells, blood vessels, bone marrow, and the lymphatic system. Hematologists diagnose and treat conditions such as anemia, clotting disorders, leukemia, and lymphoma. This therapeutic area includes a wide range of treatment options, from blood transfusions and anticoagulation therapy to bone marrow transplants and targeted cancer therapies.",
  },
  {
    color: "#C25096",
    name: "Immunology",
    content:
      "Immunology is the branch of medicine that focuses on the immune system and its role in health and disease. This therapeutic area investigates the body's defense mechanisms against infections, allergens, and autoimmune disorders. Immunologists diagnose and treat conditions like allergies, asthma, and lupus, using various treatment modalities such as immunosuppressive medications, allergy shots, and targeted biological therapies.",
  },
  {
    color: "#E64187",
    name: "Infections",
    content:
      "The Infections therapeutic area encompasses the study and treatment of infectious diseases caused by bacteria, viruses, fungi, and parasites. Infectious disease specialists diagnose and manage conditions like pneumonia, meningitis, and HIV/AIDS. Treatment options in this field include antibiotics, antiviral medications and antifungal agents to both prevent and combat these infections.",
  },
  {
    color: "#F44479",
    name: "Neurology",
    content:
      "Neurology is the branch of medicine that deals with disorders related to the nervous system. This therapeutic area covers conditions affecting the brain, spinal cord, and peripheral nerves, such as multiple sclerosis, Parkinson's disease, epilepsy, and stroke. Neurologists employ various treatment options to improve patients' neurological health.",
  },
  {
    color: "#fd6c5f",
    name: "Oncology",
    content:
      "Oncology is the medical specialty dedicated to cancer. This therapeutic area encompasses a wide range of malignancies, including breast, lung, colon, and prostate cancers. Oncologists utilize a multidisciplinary approach, incorporating surgery, radiation therapy, chemotherapy, immunotherapy, and targeted treatments to provide patients with the most effective care possible.",
  },
  {
    color: "#FC705D",
    name: "Ophthalmology",
    content:
      "Ophthalmology is the medical specialty that deals with eye diseases and disorders. This therapeutic area covers conditions such as cataracts, glaucoma, macular degeneration, and diabetic retinopathy. Ophthalmologists use advanced tools and procedures to preserve and restore patients' vision.",
  },
  {
    color: "#FC9648",
    name: "Orthopedics",
    content:
      "Orthopedics is the branch of medicine that focuses on musculoskeletal disorders. This therapeutic area involves the care of bones, joints, ligaments, tendons, and muscles, addressing conditions such as arthritis, fractures, and sports injuries. Orthopedic specialists utilize a variety of treatment options, including physical therapy, pain management, and surgical interventions, to restore patients' mobility and improve their quality of life.",
  },
  {
    color: "#FFE01D",
    name: "Rheumatology",
    content:
      "Rheumatology is the medical specialty dedicated to rheumatic diseases, which are disorders affecting the joints, muscles, and connective tissues. This therapeutic area encompasses conditions such as rheumatoid arthritis, osteoarthritis, lupus, gout, and fibromyalgia. Rheumatologists utilize a multidisciplinary approach to manage these chronic diseases, employing various treatments to alleviate symptoms and improve patients' overall well-being.",
  },
];

export default pointersData;
