import axios from "axios";
import { ContactFormType } from "../interfaces/contact";

const url = "https://prod.dianox.com/api/contact/papyrus";
// const url = "http://localhost:5000/api/contact/papyrus";

const ContactService = {
  formSubmit: (data: ContactFormType) => {
    return axios.post(url, data);
  },
};

export default ContactService;
