import React from "react";
import {
  Box,
  Button,
  chakra,
  Container,
  Flex,
  HStack,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";

const NavBar: React.FC = () => {
  return (
    <>
      <Box as="section" pb="0" bottom="0">
        <Box as="nav" px={{ base: "10", md: "24" }} w="full">
          <Flex
            justify="center"
            px={{ base: "0", md: "5" }}
            py="8"
            direction={{ base: "column", md: "row" }}
            gap="15"
            align="center"
          >
            <Flex
              direction="row"
              justify={{ base: "space-between", md: "flex-start" }}
              mr="auto"
              w={{ base: "full", md: "120px" }}
              align="center"
            >
              <Link href="/">
                <Image
                  width="120px"
                  objectFit="contain"
                  src="/images/logo.svg"
                  alt="Dianox Logo"
                />
              </Link>
              <Box display={{ base: "block", md: "none" }}>
                <Menu>
                  <MenuButton as={Button}>
                    <HamburgerIcon />
                  </MenuButton>
                  <MenuList>
                    <MenuItem as={RouterLink} to="/?section=pipeline#pipeline">
                      Pipeline
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/science">
                      Our science
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/publications">
                      Publications
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/university">
                      University discount
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/team">
                      Team
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/events">
                      Events
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/news">
                      News
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/why-invest">
                      Investors
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/?section=contact#contact">
                      Contact
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/custom-development">
                      Custom Development
                    </MenuItem>
                    {
                    /* <MenuItem as={RouterLink} to="/library">
                      Library
                    </MenuItem> */
                    }
                  </MenuList>
                </Menu>
              </Box>
            </Flex>
            <HStack
              spacing="8"
              justify={{ base: "center", md: "flex-end" }}
              align="center"
              w={{ base: "full", md: undefined }}
              display={{ base: "none", md: "flex" }}
            >
              <Link
                as={RouterLink}
                to="/?section=pipeline#pipeline"
                fontWeight="400"
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                Pipeline
              </Link>
              <Menu>
                <MenuButton
                  px="0"
                  bg="transparent"
                  as={Button}
                  // rightIcon={<ChevronDownIcon />}
                  fontWeight="400"
                  _hover={{
                    textDecoration: "none",
                    background:
                      "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  _active={{
                    textDecoration: "none",
                    background:
                      "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    filter: "brightness(75%)",
                  }}
                  transition="0.15s"
                >
                  Science
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={RouterLink}
                    to="/science"
                    fontWeight="400"
                    _hover={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    _active={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      filter: "brightness(75%)",
                    }}
                    transition="0.15s"
                  >
                    Our science
                  </MenuItem>
                  <MenuItem
                    as={RouterLink}
                    to="/publications"
                    fontWeight="400"
                    _hover={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    _active={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      filter: "brightness(75%)",
                    }}
                    transition="0.15s"
                  >
                    Publications
                  </MenuItem>
                  <MenuItem
                    as={RouterLink}
                    to="/university"
                    fontWeight="400"
                    _hover={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    _active={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      filter: "brightness(75%)",
                    }}
                    transition="0.15s"
                  >
                    University discount
                  </MenuItem>
                </MenuList>
              </Menu>
              <Menu>
                <MenuButton
                  px="0"
                  bg="transparent"
                  as={Button}
                  // rightIcon={<ChevronDownIcon />}
                  fontWeight="400"
                  _hover={{
                    textDecoration: "none",
                    background:
                      "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  _active={{
                    textDecoration: "none",
                    background:
                      "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    filter: "brightness(75%)",
                  }}
                  transition="0.15s"
                >
                  About us
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={RouterLink}
                    to="/team"
                    fontWeight="400"
                    _hover={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    _active={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      filter: "brightness(75%)",
                    }}
                    transition="0.15s"
                  >
                    Team
                  </MenuItem>
                  <MenuItem
                    as={RouterLink}
                    to="/events"
                    fontWeight="400"
                    _hover={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    _active={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      filter: "brightness(75%)",
                    }}
                    transition="0.15s"
                  >
                    Events
                  </MenuItem>
                  <MenuItem
                    as={RouterLink}
                    to="/news"
                    fontWeight="400"
                    _hover={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    _active={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      filter: "brightness(75%)",
                    }}
                    transition="0.15s"
                  >
                    News
                  </MenuItem>
                  <MenuItem
                    as={RouterLink}
                    to="/why-invest"
                    fontWeight="400"
                    _hover={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    _active={{
                      textDecoration: "none",
                      background:
                        "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      filter: "brightness(75%)",
                    }}
                    transition="0.15s"
                  >
                    Investors
                  </MenuItem>
                </MenuList>
              </Menu>
              <Link
                as={RouterLink}
                to="/?section=contact#contact"
                fontWeight="400"
                _hover={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                _active={{
                  textDecoration: "none",
                  background:
                    "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
              >
                Contact
              </Link>
              <Link
                as={RouterLink}
                to="/custom-development"
                textDecoration="none"
                _hover={{ textDecoration: "none" }}
                _active={{ textDecoration: "none" }}
              >
                <Button
                  colorScheme="blue"
                  variant="outline"
                  size="lg"
                  bg="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                  border="none"
                  _hover={{
                    bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                    border: "none",
                    filter: "brightness(125%)",
                  }}
                  _active={{
                    bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                    border: "none",
                    filter: "brightness(75%)",
                  }}
                  transition="0.15s"
                  color="white"
                >
                  Custom Development
                </Button>
              </Link>
              {
                /* <Link
                as={RouterLink}
                to="/library"
                textDecoration="none"
                _hover={{ textDecoration: "none" }}
                _active={{ textDecoration: "none" }}
              >
                <Button
                  colorScheme="blue"
                  variant="outline"
                  size="lg"
                  bg="linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)"
                  border="none"
                  _hover={{
                    bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                    border: "none",
                    filter: "brightness(125%)",
                  }}
                  _active={{
                    bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                    border: "none",
                    filter: "brightness(75%)",
                  }}
                  transition="0.15s"
                  color="white"
                >
                  Aptamer Library
                </Button>
              </Link> */
              }
            </HStack>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default NavBar;
