import {
  Container,
  Flex,
  Center,
  Button,
  SimpleGrid,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  HStack,
  Text,
  Box,
  Spacer,
  Link,
  Image,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";


import React, { useEffect } from "react";

const UniDiscount: React.FC = () => {

  const universitiesData = [
    { name: 'Aarhus University', url: 'https://www.au.dk/en/' },
    { name: 'Aalborg University', url: 'https://www.en.aau.dk/' },
    { name: 'KU Leuven', url: 'https://www.kuleuven.be/' },
    { name: 'MIT', url: 'https://web.mit.edu/' },
    { name: 'Simon Fraser University', url: 'https://www.sfu.ca/' },
    { name: 'Technical University of Denmark', url: 'https://www.dtu.dk/' },
    { name: 'University of Copenhagen', url: 'https://www.ku.dk/' },
    { name: 'University of Oxford', url: 'https://www.ox.ac.uk/' },
    { name: 'University of Southern Denmark', url: 'https://www.sdu.dk/' },
    { name: 'Vrije Universiteit Brussel', url: 'https://www.vub.be/' },
  ];

  function UniversityList() {
    return (
      <ul>
        {universitiesData.map((university, index) => (
          <li key={index}>
            <a href={university.url} target="_blank" rel="noopener noreferrer">
              {university.name}
            </a>
          </li>
        ))}
      </ul>
    );
  }




  return (
    <>
      <Flex
        flexDirection="column"
        textAlign={{ base: "center", md: "center" }}
        justifyContent="center"
        minH={{ base: "60vh", md: "100vh" }}
        margin-bottom="10px"
        border-radius="8px" /* Rounded corners for the image */
        justify="center"
        align="center"
        position="relative"
        direction="column"
        flexGrow="1"
      >
        <Flex
          width="auto"
          justify="center"
          align="center"
          maxWidth="40%"
        // minWidth="70%"

        >
          <VStack>
            <Heading
              fontWeight="600"
              textAlign={{ base: "center", md: "left" }}
              color="black"
              size={{ base: "xl", md: "2xl" }}
              bg="transparent"
              margin="30px"
            >
              University discounts
            </Heading>
            <img
              src="images/library.jpg"
              style={{
                maxWidth: '100%',   // Set the image to 50% of the container width
                height: 'auto',    // Maintain the aspect ratio
                borderRadius: '16px', // Rounded corners
                boxShadow: 'lg'
              }}
            />
            {/* <Flex
              maxWidth="45%"
              minWidth="45%"
              align="center"
            > */}
            <Text
              align="left"
              fontWeight="400"
            >
              <br></br>
              At Dianox, we understand the importance of advanced research
              in shaping the future of biotechnology.
              To further our commitment and support to the academic community, we are proud to offer 10% discount on our top-quality aptamers to select universities worldwide.
              <br></br>
              <br></br>
            </Text>
            <Text
              align="left"
              fontWeight="400"
              fontSize="20px"
            >
              <b>Participating universities:</b>
              <br></br>
              <UniversityList />
            </Text>
            <br></br>
            <Text>
              <i>(If your university is not listed, it may still be eligible. Read on to find out how.)</i>
              <br></br>
              To utilize the discount, simply contact us from your university email when placing an order.
              <br></br>
              <br></br>


            </Text>
            <Flex
              direction={{ base: "column", md: "row" }}
              w="full"
              gap="4"
              justify="center"
              margin="6"
            >
              <Link
                as={RouterLink}
                to="/library"
                textDecoration="none"
                _hover={{ textDecoration: "none" }}
                _active={{ textDecoration: "none" }}
              >
                <Button
                  colorScheme="blue"
                  variant="outline"
                  size="lg"
                  bg="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                  border="none"
                  _hover={{
                    bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                    border: "none",
                    filter: "brightness(125%)",
                  }}
                  _active={{
                    bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                    border: "none",
                    filter: "brightness(75%)",
                  }}
                  transition="0.15s"
                  color="white"
                >
                  Browse Aptamer Library
                </Button>
              </Link>
            </Flex>
            <br></br>
            <br></br>
            <Text
              align="left"
            >
              <b>Is your university not listed?</b>
              <br></br>
              Researchers from institutions not currently listed can express interest in partnering with us for exclusive discounts.

              Please fill out the contact form below, and our dedicated team will reach out to discuss potential collaboration opportunities.
              Sign up to see if your university is eligible for a discount:
            </Text>
          </VStack>

        </Flex>
      </Flex >
    </>
  );
};

export default UniDiscount;
