import {
  Button,
  Container,
  Flex,
  GridItem,
  Heading,
  HStack,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

const CookiePolicy: React.FC = () => {
  const [showCookie, setShowCookie] = useState<boolean>(false);

  useEffect(() => {
    const consentCookie = Cookies.get("dianox_accept_all_cookies");
    if (!consentCookie) {
      setShowCookie(true);
    }
  }, []);

  const setCookie = () => {};

  if (!showCookie) {
    return null;
  }

  return (
    <Flex
      w="100vw"
      h="300px"
      position="fixed"
      zIndex="2000"
      bottom="0"
      bg="white"
    >
      <Container maxW="container.xl">
        <SimpleGrid columns={12} spacing="6">
          <GridItem colSpan={8}>
            <Flex direction="column" w="full" gap="8" pt="20">
              <Heading>Cookies on this site</Heading>
              <Text>
                By clicking accept, you agree to storing of cookies on your
                device to enchance site navigation. Cookies are important to the
                proper functioning of a site. To improve your experience,we use
                cookies to remember log-in details and provide secure log-in,
                collect statistics to optimize site functionality, and deliver
                content tailored to your interests.{" "}
              </Text>
            </Flex>
          </GridItem>
          <GridItem colSpan={4}>
            <HStack w="full" justify="center" pt="24">
              <Button
                onClick={() => {
                  setShowCookie(false);
                  Cookies.set("dianox_accept_all_cookies", "no", {
                    expires: 45,
                    path: "",
                  });
                }}
                variant="outline"
                size="lg"
                colorScheme="purple"
                fontSize="sm"
              >
                Decline
              </Button>
              <Button
                variant="solid"
                size="lg"
                colorScheme="purple"
                fontSize="sm"
                bg="linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)"
                border="none"
                _hover={{
                  bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  border: "none",
                  filter: "brightness(125%)",
                }}
                _active={{
                  bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  border: "none",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
                color="white"
                onClick={() => {
                  console.log("Triggerring this function");
                  Cookies.set("dianox_accept_all_cookies", "yes", {
                    expires: 45,
                    path: "",
                  });
                  setShowCookie(false);
                }}
              >
                Accept all Cookies
              </Button>
            </HStack>
          </GridItem>
        </SimpleGrid>
      </Container>
    </Flex>
  );
};

export default CookiePolicy;
