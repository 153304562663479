import {
  Container,
  Flex,
  GridItem,
  SimpleGrid,
  Image,
  Text,
  Box,
} from "@chakra-ui/react";
import React from "react";

const ScienceCare: React.FC = () => {
  return (
    <>
      <Flex
        w="full"
        justify="center"
        align="center"
        h={{ base: "auto", lg: "40vh" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <Flex justify="center" align="center">
                <Image
                  src="/images/drugs1.jpg"
                  alt="drugs"
                  maxHeight="300px"
                  width="auto"
                />
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="2xl" fontWeight="600">
                  Drugging the undruggable
                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    85% of the human proteome remains undruggable with small
                    molecules and unreachable by antibodies. Most proteins are
                    undruggable due to their ccomplex structure and lack of
                    binding sites. Undruggable proteins play a critical role in
                    disease progression, and finding ways to drug them can
                    unlock new medicine for untreated diseases.
                  </Text>
                </Box>
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
    </>
  );
};

export default ScienceCare;
