import {
  Container,
  Flex,
  GridItem,
  SimpleGrid,
  Image,
  Text,
  Button,
  Box,
} from "@chakra-ui/react";
import React from "react";

const IpRights: React.FC = () => {
  return (
    <>
      <Flex
        w="full"
        justify="center"
        align="center"
        h={{ base: "auto", lg: "auto" }}
      >
        <Container maxW="container.xl" centerContent>
          <Text w="full" textAlign="left" fontSize="2xl" fontWeight="600">
            Intellectual property rights
          </Text>
          <Box mt="6">
            <Text lineHeight="10" w="full">
              Our commitment is to support your scientific endeavors and breakthroughs. However, we do ask that if our aptamers are used in published research, appropriate acknowledgment of our company is included.
            </Text>
            <br></br>
            <Text gap="4" lineHeight="10" w="full">
              In an effort to foster innovation and research, we believe in providing unrestricted access to our aptamer library. Therefore, we would like to clarify that there are no intellectual property rights attached to the purchase and use of our aptamers.
            </Text>
            <Flex justify="center" align="center">
              <Image src="/images/apt1.png" alt="dna-aptamer" />
            </Flex>
            <Text lineHeight="10" w="full">
              We simply offer aptamer sequence data. When you purchase from our library, you are free to use the aptamers in your research. However, it is your responsibility to ensure that there is no patent infringement, if you decide to use the aptamers in a commercial application or pursue intellectual property.
            </Text>
            <br></br>
            <Text lineHeight="10" w="full">
              Please note that while we do not assert any intellectual property rights over our aptamers, we cannot provide assurance regarding third-party intellectual property rights. We encourage customers to seek legal advice if they have any concerns about intellectual property related to the specific use of our aptamers.
            </Text>

          </Box>
        </Container >

      </Flex>
    </>
  );
};

export default IpRights;
