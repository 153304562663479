import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import About from "../../components/home/about";
import Contact from "../../components/home/contact";
import Footer from "../../components/footer";
import Header from "../../components/home/header";
import Library from "../../components/home/library";
import Molecules from "../../components/home/molecules";
import Nature from "../../components/home/nature";
import Pointers from "../../components/home/pointers";
import Pricing from "../../components/home/pricing";
import Proof from "../../components/home/proof";
import TrustedBy from "../../components/home/trusted-by";
import { useSearchParams } from "react-router-dom";

const HomePage: React.FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const section = searchParams.get("section");

  const scrollToMyRef = (id: string) => {
    const ref = document.getElementById(id);
    setTimeout(function () {
      ref?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  };

  useEffect(() => {
    if (section) {
      scrollToMyRef(section);
    }
    setSearchParams();
  }, [section]);

  return (
    <>
      <Box position="relative" pb="0" className="parent-container">
        <Flex w="full" direction="column" gap="0" overflowX="hidden">
          <Header />
          <Proof />
          <About />
          <Nature />
          <Pointers />
          <TrustedBy />
          <Pricing />
          <Molecules />
          <Library />
          <Contact />
        </Flex>

        <Footer />
      </Box>
    </>
  );
};

export default HomePage;
