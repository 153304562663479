import React, { useState } from "react";
import {
  Flex,
  VStack,
  Heading,
  Text,
  Image,
  SimpleGrid,
  GridItem,
  Box,
  Container,
  Link,
  Button,
  Divider,
  HStack,
  Circle,
} from "@chakra-ui/react";
import pointersData from "./data";

const Pointers: React.FC = () => {
  const [selectedPointer, setSelectedPointer] = useState(pointersData[0]);
  return (
    <Flex
      direction="column"
      minH="80vh"
      py="10"
      pt={{ base: "10", lg: "40" }}
      zIndex="10"
      bg="white"
      gap="20"
      overflowY="visible"
    >
      <VStack spacing="3" px={{ base: "3", md: "0" }}>
        <Heading w="full" textAlign="center">
          Target any therapeutic area
        </Heading>
        <Text w="full" fontSize="md" textAlign="center">
          Imagine the world’s most powerful discovery platform at your disposal.
        </Text>
      </VStack>
      <Container maxW="container.xl" centerContent>
        <SimpleGrid columns={12} spacing="0" pb="0">
          <GridItem
            colSpan={{ base: 12, md: 4 }}
            display={{ base: "none", lg: "block" }}
            order={{ base: 1, md: 0 }}
            m="0"
            pt={{ base: "0", md: "8" }}
            pl="0"
            h="full"
          >
            <Flex
              direction="column"
              align="center"
              gap="4"
              h="full"
              justify="center"
            >
              <Image src="/images/therapy_areas2.png" w="250" />
            </Flex>
          </GridItem>
          <GridItem
            colSpan={{ base: 12, md: 4 }}
            order={{ base: 0, md: 1 }}
            m="0"
            p="0"
            mb={{ base: 0, md: "6" }}
          >
            <Flex direction="column" align="center" h="full">
              {pointersData.map((pointer, idx: number) => (
                <HStack
                  w="full"
                  align="center"
                  justify="center"
                  spacing="4"
                  onClick={() => setSelectedPointer(pointer)}
                  cursor="pointer"
                >
                  <VStack spacing="0" align="center" justify="center">
                    <Divider
                      orientation="vertical"
                      borderWidth="1px"
                      borderColor="gray.300"
                      h="20px"
                    />
                    <Circle size="10px" bg={pointer.color}></Circle>
                    <Divider
                      orientation="vertical"
                      borderWidth="1px"
                      borderColor="gray.300"
                      h="20px"
                    />
                  </VStack>
                  <Text
                    color="emphasized"
                    fontWeight="400"
                    w="200px"
                    fontSize="xl"
                  >
                    {pointer.name}
                  </Text>
                </HStack>
              ))}
            </Flex>
          </GridItem>
          <GridItem
            colSpan={{ base: 12, md: 4 }}
            order={{ base: 0, md: 1 }}
            m="0"
            p="0"
            mb={{ base: 0, md: "6" }}
          >
            <Flex
              direction="column"
              gap="6"
              justify={{ base: "center", lg: "flex-start" }}
              w="full"
              mt={{ base: 4, lg: 0 }}
            >
              <Heading w="full" textAlign={{ base: "center", lg: "left" }}>
                {selectedPointer.name}
              </Heading>
              <Text w="full" textAlign={{ base: "center", lg: "left" }}>
                {selectedPointer.content}
              </Text>
            </Flex>
          </GridItem>
        </SimpleGrid>
      </Container>
    </Flex>
  );
};

export default Pointers;
