import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Text,
  Link,
  Button,
} from "@chakra-ui/react";
import React from "react";

const TrustedBy: React.FC = () => {
  return (
    <Flex
      w="full"
      h="full"
      bgGradient="linear-gradient(98.65deg,#00535B 7.37%,#00C4D1 98.43%)"
      py="12"
      direction="column"
      gap="8"
    >
      <Flex
        direction="column"
        gap="10"
        justifyContent="center"
        alignItems="center"
        py="8"
        px="2"
      >
        <Heading
          fontSize={{ base: "2xl", lg: "3xl" }}
          textAlign="center"
          color="white"
        >
          Watch our platform live
        </Heading>
        <Link
          href="mailto:hello@dianox.com?Subject=Dianox demo AI drug platform"
          isExternal
          _hover={{
            textDecoration: "none",
          }}
        >
          <Button
            size="lg"
            bg="white"
            variant="outline"
            onClick={() => {
              document.getElementById("service")!.scrollIntoView();
            }}
            fontWeight="700"
            border="1px solid"
            borderColor="#489FDA"
            transition="0.15s"
            color="#489FDA"
            _hover={{
              bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
              border: "none",
              color: "white",
            }}
            _active={{
              bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
              border: "none",
              color: "white",
              filter: "brightness(75%)",
            }}
          >
            Book demo
          </Button>
        </Link>
      </Flex>
      <Flex w="full" direction="column" py="12">
        <Container maxW="container.md">
          <Text
            fontSize={{ base: "md", lg: "sm" }}
            color="white"
            w="full"
            textAlign="center"
          >
            Trusted by
          </Text>
          <Flex
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "space-between" }}
            align="center"
            gap={{ base: 10, md: 4 }}
            mt={{ base: 10, lg: 0 }}
          >
            <Image src="/images/rga.svg" maxW="200px" />
            <Image src="/images/nvidia.svg" maxW="200px" />
            <Image src="/images/haleon.svg" maxW="200px" />
          </Flex>
        </Container>
      </Flex>
    </Flex>
  );
};

export default TrustedBy;
