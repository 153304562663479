import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Text,
  Link,
  Button,
} from "@chakra-ui/react";
import React from "react";

const TrustedBy: React.FC = () => {
  return (
    <Flex
      w="full"
      h="full"
      bgGradient="linear-gradient(98.65deg,#00535B 7.37%,#00C4D1 98.43%)"
      py="2"
      direction="column"
      gap="8"
    >
      <Flex w="full" direction="column" py="12">
        <Container maxW="container.md">
          <Text
            fontSize={{ base: "2xl", lg: "3xl" }}
            color="white"
            w="full"
            textAlign="center"
            py="4"
          >
            Trusted by
          </Text>
          <Flex
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "space-between" }}
            align="center"
            gap={{ base: 10, md: 4 }}
            mt={{ base: 10, lg: 0 }}
          >
            <Image src="/images/rga.svg" maxW="200px" />
            <Image src="/images/nvidia.svg" maxW="200px" />
            <Image src="/images/haleon.svg" maxW="200px" />
          </Flex>
        </Container>
      </Flex>
    </Flex >
  );
};

export default TrustedBy;

<Heading
  fontSize={{ base: "2xl", lg: "3xl" }}
  textAlign="center"
  color="white"
>
  Watch our platform live
</Heading>
