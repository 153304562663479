import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";

import LibraryInfoBanner from "../../components/library/info_banner";
import LibraryLanding from "../../components/library/landing"
import Contact from "../../components/home/contact_sales"
import MoleculeAreas from "../../components/library/molecule_areas";
import LibraryAccess from "../../components/library/library_access";
import IpRights from "../../components/library/ip_rights";
import TrustedBy from "../../components/library/trusted_by";

const LibraryPage: React.FC = () => {
  return (
    <>
      <Box position="relative" pb="0" className="parent-container">
        <Flex w="full" direction="column" gap="10" pb="16" justify="center">
          <NavBar />
          <LibraryLanding />
          <LibraryInfoBanner />
          <MoleculeAreas />
          <TrustedBy />
          <LibraryAccess />
          <IpRights />
          <Contact />
        </Flex>
        <Footer />
      </Box>
    </>
  );
};

export default LibraryPage;
