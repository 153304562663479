import * as React from "react";
import { useRoutes } from "react-router-dom";
import HomePage from "../pages/home";
import Invest from "../pages/invest";
import NewsPage from "../pages/news";
import DanishChamberOfCommercePage from "../pages/news/danish-chamber-of-commerce";
import EUFundPage from "../pages/news/eu-fund";
import InnoboosterFund from "../pages/news/innobooster-granted";
import PresidentObamaPage from "../pages/news/president-obama";
import RighospitaletPage from "../pages/news/righospitalet";
import ShenzenPage from "../pages/news/sdg-shenzen";
import Publications from "../pages/publications";
import Science from "../pages/science";
import Teampage from "../pages/team";
import LibraryPage from "../pages/library";
import CustomDevelopmentPage from "../pages/custom_development";
import EventsPage from "../pages/events";
import UniDiscountPage from "../pages/uni_discount";

const Routes: React.FC = () => {
  let element = useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
    { path: "/science", element: <Science /> },
    { path: "/why-invest", element: <Invest /> },
    { path: "/team", element: <Teampage /> },
    {
      path: "/news",
      element: <NewsPage />,
    },
    { path: "/news/innobooster-granted", element: <InnoboosterFund /> },
    {
      path: "/news/partnership-with-rigshospitalet",
      element: <RighospitaletPage />,
    },
    {
      path: "/news/dianox-meets-president-obama",
      element: <PresidentObamaPage />,
    },
    {
      path: "/news/eu-fund",
      element: <EUFundPage />,
    },
    {
      path: "/news/interviewed-by-danish-chamber-of-commerce",
      element: <DanishChamberOfCommercePage />,
    },
    {
      path: "/news/sdg-accelerator-shenzhen",
      element: <ShenzenPage />,
    },
    { path: "/publications", element: <Publications /> },
    {
      path: "/university", element: <UniDiscountPage />
    },
    {
      path: "/library", element: <LibraryPage />
    },
    {
      path: "/custom-development", element: <CustomDevelopmentPage />
    },
    {
      path: "/events", element: <EventsPage />
    }
  ]);

  return element;
};

export default Routes;
