import React from "react";
import {
  Box,
  Container,
  Flex,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

const Service: React.FC = () => {
  return (
    <Flex
      direction="column"
      py="10"
      minH="70vh"
      id="service"
      pt="40"
      zIndex="10"
      bg="white"
      gap="20"
      maxW="100vw"
      overflowX="hidden"
    >
      <VStack spacing="3" px={{ base: "3", md: "0" }}>
        <Heading w="full" textAlign="center" size={{ base: "xl", md: "2xl" }}>
          Drug design flow
        </Heading>
        <Text w="full" fontSize="md" textAlign="center">
          Our flexible AI engine can generate structured nucleic acids for
          specific protein targets to rapidly design novel drug candidates.
        </Text>
      </VStack>
      <Container
        maxW="container.xl"
        overflowX={{ base: "scroll", md: "hidden" }}
      >
        <Box
          display="flex"
          flexBasis="100%"
          position="relative"
          flexDirection="column"
          flexGrow="1"
          minH="125px"
          minW="300px"
          bg="url('/images/ai_engine.png')"
          bgPosition="left 0% top 50%"
          bgSize="contain"
          bgRepeat="no-repeat"
          p="4"
          borderRadius="16px"
          bgColor="black"
          mx="5"
        >
          <SimpleGrid columns={12} w="full">
            <GridItem colSpan={4}></GridItem>
            <GridItem colSpan={{ base: 12, md: 8 }}>
              <Flex direction="column" p="0" h="full" w="full" py="5">
                <Text fontSize="xl" w="full" textAlign="left" color="white">
                  Dianox AI Platform
                </Text>
                <Text fontSize="sm" w="full" textAlign="left" color="white">
                  Our ML engine enables rapid design of nucleic acids and
                  interactions with target proteins.
                </Text>
              </Flex>
            </GridItem>
          </SimpleGrid>
          <Box
            className="arrow"
            position="absolute"
            bottom="-10"
            left="200"
            transform="rotate(90deg)"
          >
            <Image src="/images/arrow.png" />
          </Box>
          <Box
            className="arrow"
            position="absolute"
            bottom="-10"
            right="200"
            transform="rotate(270deg)"
          >
            <Image src="/images/arrow.png" />
          </Box>
        </Box>
        <Flex direction="row" py="10">
          <Flex
            position="relative"
            pl="20"
            py="8"
            pr="4"
            direction="column"
            boxShadow="lg"
            flexGrow="1"
            minW="300px"
            m="5"
            mb="0"
            bgImage="url('/images/molecule1.png')"
            bgPosition="left 10px top 20px"
            bgRepeat="no-repeat"
            bgSize="auto 150px"
            borderRadius="16px"
          >
            <Box className="arrow" position="absolute" top="20" right="-6">
              <Image src="/images/arrow.png" />
            </Box>
            <Flex direction="column" zIndex="10">
              <Text fontSize="xl" w="full" textAlign="left">
                Identify targets
              </Text>
              <Text fontSize="sm">
                Identifies promising drug targets by exploring all possible
                relationships among biological entities in a comprehensive map.
              </Text>
            </Flex>
          </Flex>
          <Flex
            position="relative"
            pl="20"
            py="8"
            pr="4"
            direction="column"
            boxShadow="lg"
            flexGrow="1"
            minW="300px"
            m="5"
            mb="0"
            bgImage="url('/images/molecule2.png')"
            bgPosition="left 8px top 30px"
            bgRepeat="no-repeat"
            bgSize="auto 120px"
            borderRadius="16px"
          >
            <Box className="arrow" position="absolute" top="20" right="-6">
              <Image src="/images/arrow.png" />
            </Box>

            <Text fontSize="xl">Screen virtually</Text>
            <Text fontSize="sm">
              Our platform screens up to trillions of novel nucleic acid protein
              interactions in silico to discover potential drug candidates with
              desired properties.
            </Text>
          </Flex>
          <Flex
            position="relative"
            pl="20"
            py="8"
            pr="4"
            direction="column"
            boxShadow="lg"
            flexGrow="1"
            minW="300px"
            m="5"
            mb="0"
            bgImage="url('/images/molecule3.png')"
            bgPosition="left 0px top 30px"
            bgRepeat="no-repeat"
            bgSize="auto 120px"
            borderRadius="16px"
          >
            <Text opacity="1" fontSize="xl">
              Optimize lead
            </Text>
            <Text fontSize="sm">
              The most promising compounds are further optimized in vitro using
              chemically modifications to improve pharmacokinetic properties.
            </Text>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Service;
