import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import Righospitalet from "../../components/news/righospitalet";

const RighospitaletPage: React.FC = () => {
  return (
    <>
      <Box position="relative" pb="0" className="parent-container">
        <Flex w="full" direction="column" gap="0" pb="16">
          <NavBar />
          <Righospitalet />
        </Flex>
        <Footer />
      </Box>
    </>
  );
};

export default RighospitaletPage;
