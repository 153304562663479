import { Box, chakra, Flex, Heading } from "@chakra-ui/react";
import React from "react";

const PublicationsHeader: React.FC = () => {
  return (
    <>
      <Flex
        h="40vh"
        w="full"
        bgImage="url('/images/publications_header.jpg')"
        bgSize="contain"
        bgPosition="top"
      >
        <Flex
          h="40vh"
          w="full"
          justify="center"
          alignItems="center"
          bg="rgba(0,0,0,0.7)"
        >
          <Heading
            fontWeight="600"
            textAlign={{ base: "center", md: "center" }}
            color="white"
            size={{ base: "xl", md: "2xl" }}
            w="full"
            bg="transparent"
          >
            Publications
          </Heading>
        </Flex>
      </Flex>
    </>
  );
};

export default PublicationsHeader;
