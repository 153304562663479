import {
  Container,
  Flex,
  GridItem,
  SimpleGrid,
  Image,
  Text,
  Button,
  Box,
} from "@chakra-ui/react";
import React from "react";

const DevelopmentServices: React.FC = () => {
  return (
    <>
      <Flex
        w="full"

        justify="center"
        align="center"
        h={{ base: "auto", lg: "auto" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">

            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 0, md: 2 }}>
              <Flex justify="center" align="center">
                <Image src="/images/nn.png" alt="2d-rna-aptamer" />
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 1, md: 1 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="2xl" fontWeight="600">
                  Aptamer generation
                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    Our computational methods allow us to generate novel aptamers using AI.
                    Based on our large library of characterized aptamers, we can rapidly 
                    generate novel aptamers against specific targets.
                  </Text>

                </Box>
              </Flex>
              <Flex
                direction={{ base: "column", md: "row" }}
                gap="6"
                justifyContent="flex-start"
                mt="10"
                px={{ base: "12", md: 0 }}
              >
                <Button
                  colorScheme="blue"
                  variant="outline"
                  size="lg"
                  onClick={() => {
                    document.getElementById("contact")!.scrollIntoView();
                  }}
                  bg="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                  border="none"
                  _hover={{
                    bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                    border: "none",
                    filter: "brightness(125%)",
                  }}
                  _active={{
                    bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                    border: "none",
                    filter: "brightness(75%)",
                  }}
                  transition="0.15s"
                  color="white"
                >
                  Request custom aptamer
                </Button>
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
      <Flex
        w="full"

        justify="center"
        align="center"
        h={{ base: "auto", lg: "auto" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 0, md: 2 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="2xl" fontWeight="600">
                  In silico analysis
                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    Using our algorithms and AI technology, we model the generated aptamers and predict properties such as binding affinity and folding behavior.
                  </Text>
                  <br></br>
                  <Text gap="4" lineHeight="10" w="full">
                    We leverage AI to accelerate drug discovery. Our skilled team of AI engineers and biologists use advanced molecular modeling, virtual screening, and structure-based drug design to identify promising lead candidates and predict target interactions. This approach helps rationalize design and selection of development candidates, saving time and resources.
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 1, md: 1 }}>
              <Flex justify="center" align="center">
                <Image src="/images/apt_2d.png" alt="2d-rna-aptamer" />
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
      <Flex
        w="full"

        justify="center"
        align="center"
        h={{ base: "auto", lg: "auto" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">

            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 0, md: 2 }}>
              <Flex justify="center" align="center">
                <Image src="/images/microscope.svg" alt="bio-physical analysis" />
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 1, md: 1 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="2xl" fontWeight="600">
                  Biophysical analysis
                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    Understanding molecular interactions of drug candidates is crucial for successful development.
                    Our dedicated team of biophysicists and structural biologists employ spectroscopy, anisotropy and
                    surface plasmon resonance (SPR) to elucidate molecular properties and binding kinetics of aptamer-target 
                    complexes to guide optimization and lead selection.
                  </Text>

                </Box>
              </Flex>
              <Flex
                direction={{ base: "column", md: "row" }}
                gap="6"
                justifyContent="flex-start"
                mt="10"
                px={{ base: "12", md: 0 }}
              >
                <Button
                  colorScheme="blue"
                  variant="outline"
                  size="lg"
                  onClick={() => {
                    document.getElementById("contact")!.scrollIntoView();
                  }}
                  bg="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
                  border="none"
                  _hover={{
                    bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                    border: "none",
                    filter: "brightness(125%)",
                  }}
                  _active={{
                    bg: "linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)",
                    border: "none",
                    filter: "brightness(75%)",
                  }}
                  transition="0.15s"
                  color="white"
                >
                  Request custom aptamer
                </Button>
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
    </>
  );
};

export default DevelopmentServices;
