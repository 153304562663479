import {
  Flex,
  GridItem,
  SimpleGrid,
  Image,
  Container,
  Heading,
  Text,
} from "@chakra-ui/react";
import React from "react";

const FounderBio: React.FC = () => {
  return (
    <Flex w="full" minH="30vh" my={{ base: "8", md: "24" }}>
      <Container maxW="container.xl">
        <SimpleGrid columns={12} spacing={4}>
          <GridItem colSpan={{ base: 12, lg: 4 }} justifyContent="flex-start">
            <Flex w="full" h="full" align="center" justify="center">
              <Image
                src="/images/founder1.jpg"
                alt="Huram Konjen"
                borderRightRadius="full"
                border="none"
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 8 }}>
            <Flex
              w="full"
              direction="column"
              justify="center"
              align="flex-start"
              gap="6"
              h="full"
              px="4"
              position="relative"
            >
              <Heading>Our founder's story</Heading>
              <Text>
                14 years ago my brother passed away from an untreated disease.
                My brother was always someone I looked up to, and losing him so
                early and abruptly shaped my trajectory in a tremendous way. My
                brother was in his early thirties when he passed away, and his
                condition was beyond the reach of medical science. One day, he
                simply went to bed and never woke up. The doctors were unable to
                diagnose his illness, let alone provide any effective treatment.
              </Text>
              <Text>
                My brother's death underscored the need for innovation in the
                pharmaceutical industry. If better treatments were available,
                his illness could have been managed, extending his life. It is
                essential to recognize that untreatable diseases are a
                significant challenge, and developing innovative approaches to
                treat them is critical to addressing the health needs of
                patients and communities.
              </Text>
              <Text fontSize="lg" fontWeight="600">
                Behind every molecule we discover, there's a life that we aim to
                transform. We are not just developing drugs, we are creating
                hope and improving the human condition one patient at a time.
              </Text>
              <Text>Huram Konjen, CEO</Text>
              <Image
                src="/images/quotes.png"
                position="absolute"
                top={{ base: "600", lg: "320" }}
                left="-5"
              />
            </Flex>
          </GridItem>
        </SimpleGrid>
      </Container>
    </Flex>
  );
};

export default FounderBio;
