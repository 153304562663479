import { Flex, Heading } from "@chakra-ui/react";
import React from "react";

const NewsHeader: React.FC = () => {
  return (
    <Flex
      w="full"
      h={{ base: "auto", lg: "30vh" }}
      justify="center"
      align="center"
      py={{ base: 10, lg: 0 }}
    >
      <Heading
        fontWeight="600"
        textAlign={{ base: "center", md: "center" }}
        color="black"
        size={{ base: "xl", md: "2xl" }}
        w="full"
        bg="transparent"
      >
        Latest news
      </Heading>
    </Flex>
  );
};

export default NewsHeader;
