import { Flex, Container, Box, VStack, Text, Heading } from "@chakra-ui/react";
import React from "react";

const CustomDevelopmentInfoBanner: React.FC = () => {
  return (
    <Box
      w="full"
      h="full"
      bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
      py="8"
    >
      <Container maxW="container.xl">
        <Flex
          direction={{ base: "column", md: "row" }}
          gap="0"
          justifyContent="space-between"
          align="center"
        >
          <VStack spacing="">
            <Heading w="full" textAlign="center" color="white">
              AI SELEX
            </Heading>
            <Text w="full" fontSize="md" textAlign="center" color="white">
              Generate novel aptamers
            </Text>
          </VStack>
          <VStack spacing="1">
            <Heading w="full" textAlign="center" color="white">
              Docking
            </Heading>
            <Text w="full" fontSize="md" textAlign="center" color="white">
              Analysis and property prediction
            </Text>
          </VStack>
          <VStack spacing="1">
            <Heading w="full" textAlign="center" color="white">
              Biophysics
            </Heading>
            <Text w="full" fontSize="md" textAlign="center" color="white">
             Test for desired properties
            </Text>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default CustomDevelopmentInfoBanner;
