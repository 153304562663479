import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Footer from "../../components/footer";
import InvestContent from "../../components/invest/content";
import InvestHeader from "../../components/invest/header";
import NavBar from "../../components/navbar";

const Invest: React.FC = () => {
  return (
    <>
      <Box position="relative" pb="0" className="parent-container">
        <Flex w="full" direction="column" gap="0" pb="16">
          <NavBar />
          <InvestHeader />
          <InvestContent />
        </Flex>

        <Footer />
      </Box>
    </>
  );
};

export default Invest;
