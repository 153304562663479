import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import FounderBio from "../../components/team/founder";
// import TeamHeader from "../../components/team/header";
import TeamMembers from "../../components/team/members";

const Teampage: React.FC = () => {
  return (
    <>
      <Box position="relative" pb="0" className="parent-container">
        <Flex w="full" direction="column" gap="10" pb="16" justify="center">
          <NavBar />
          <FounderBio />
          {/* Commented out <TeamHeader /> Commented out */}
          <TeamMembers />
        </Flex>

        <Footer />
      </Box>
    </>
  );
};

export default Teampage;
