import {
  Flex,
  Heading,
  VStack,
  Text,
  Container,
  Circle,
} from "@chakra-ui/react";

const Library: React.FC = () => {
  return (
    <Flex
      direction="column"
      py="10"
      minH="70vh"
      pt="40"
      zIndex="10"
      bg="white"
      gap="20"
      justify="center"
    >
      <VStack spacing="3" px={{ base: "3", md: "0" }}>
        <Heading w="full" textAlign="center">
          The largest known drug library
        </Heading>
        <Text w="full" fontSize="md" textAlign="center">
          Rapidly design structured nucleic acid drug candidates.
        </Text>
      </VStack>
      <Container maxW="container.xl">
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-evenly"
          align="center"
          gap={{ base: "10", md: "0" }}
        >
          <Circle size="50px" bg="gray.400" boxShadow="lg">
            <Text color="white" fontWeight="400">
              15K
            </Text>
          </Circle>
          <Circle size="60px" bg="gray.400" boxShadow="lg">
            <Text color="white" fontWeight="400">
              250K
            </Text>
          </Circle>
          <Circle size="80px" bg="gray.400" boxShadow="lg">
            <Text color="white" fontWeight="400">
              10 M
            </Text>
          </Circle>
          <Circle size="120px" bg="gray.400" boxShadow="lg">
            <Text color="white" fontWeight="400">
              2 billion
            </Text>
          </Circle>
          <VStack spacing="8">
            <Circle
              size="300px"
              bgGradient="linear-gradient(98.65deg,#EB6280 7.37%,#F1956E 98.43%)"
              boxShadow="lg"
            >
              <Text color="white" fontWeight="600" fontSize="4xl">
                Unlimited
              </Text>
            </Circle>
            <Text color="#595959" fontWeight="400" fontSize="sm">
              Up to 10<Text as="sup">60</Text> compounds with binding for each
              target
            </Text>
          </VStack>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Library;
