import {
  Container,
  Flex,
  GridItem,
  SimpleGrid,
  Image,
  Text,
  Box,
} from "@chakra-ui/react";
import React from "react";

const ScienceProcess: React.FC = () => {
  return (
    <>
      <Flex
        w="full"
        justify="center"
        align="center"
        h={{ base: "auto", lg: "40vh" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <Flex justify="center" align="center">
                <Image src="/images/dna.svg" alt="test-tube" />
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="2xl" fontWeight="600">
                  Short sequences of artificial DNA & RNA
                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    Our drug library is orders of magnitude better than existing
                    libraries. We collect all physicochemical properties,
                    targets and binding affinity of each sequence. This library
                    is used to train AI and Machine Learning models to design
                    new specific drugs against any protein. In a matter of
                    hours, our AI tests every possible drug against a single
                    protein target. Once it find drugs that bind, these are
                    tested against other proteins to ensure the drug is
                    specific. This produces very high quality lead drug
                    candidates.
                  </Text>
                </Box>
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
    </>
  );
};

export default ScienceProcess;
