import React from "react";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Text,
  Button,
  Container,
  Stack,
  HStack,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";

const Footer: React.FC = () => {
  return (
    <>
      <Box
        as="section"
        pb="0"
        bottom="0"
        bgGradient="linear-gradient(98.65deg,#00535B 7.37%,#00C4D1 98.43%)"
      >
        <Box as="nav" bg="bg-surface" px={{ base: "10", md: "24" }}>
          <SimpleGrid columns={3}>
            <GridItem colSpan={{ base: 3, md: 1 }}>
              <Flex
                justify={{ base: "center", md: "flex-start" }}
                px={{ base: "0", md: "5" }}
                py={{ base: "7", md: "10" }}
                direction={{ base: "row", md: "row" }}
                gap="15"
              >
                <Flex
                  direction="row"
                  justify={{ base: "center", md: "flex-start" }}
                  w="100px"
                  align="center"
                >
                  <Link href="/">
                    <Image
                      width="100px"
                      objectFit="contain"
                      src="/images/logo_bw.png"
                      alt="Dianox Logo"
                    />
                  </Link>
                </Flex>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 3, md: 1 }}>
              <Flex
                h="full"
                w="full"
                justify="center"
                py={{ base: "2", md: "10" }}
                align="center"
                gap="2"
                flexWrap="wrap"
                direction="row"
              >
                <Text fontSize={{ base: "xs", md: "sm" }} color="white">
                  © Dianox
                </Text>
                <Text fontSize={{ base: "xs", md: "sm" }} color="white">
                  &#x2022;&nbsp;&nbsp;Copenhagen
                </Text>
                <Text fontSize={{ base: "xs", md: "sm" }} color="white">
                  &#x2022;&nbsp;&nbsp;Denmark
                </Text>
                <Link
                  href="mailto:hello@dianox.com"
                  isExternal
                  _hover={{ textDecoration: "none", color: "#ebe9e8" }}
                  _active={{ textDecoration: "none", color: "#609ac3" }}
                  transition="0.15s"
                >
                  <Text fontSize="sm" color="white">
                    &#x2022;&nbsp;&nbsp;hello@dianox.com
                  </Text>
                </Link>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 3, md: 1 }}>
              <Flex
                justifyContent={{ base: "center", md: "flex-end" }}
                gap="4"
                direction="row"
                ml={{ base: "none", md: "auto" }}
                py={{ base: "2", md: "10" }}
                align="center"
              >
                <Link href="https://twitter.com/_dianox" isExternal>
                  <IconButton
                    aria-label="Twitter"
                    icon={
                      <Icon
                        as={FaTwitter}
                        color="white"
                        boxSize={6}
                        _hover={{ color: "#ebe9e8" }}
                        _active={{ color: "#609ac3" }}
                        transition="0.15s"
                      />
                    }
                    variant="ghost"
                    _hover={{
                      bg: "transparent",
                    }}
                    _active={{ bg: "transparent" }}
                  />
                </Link>
                <Link
                  href="https://www.linkedin.com/company/dianox/"
                  isExternal
                >
                  <IconButton
                    aria-label="Linkedin"
                    icon={
                      <Icon
                        as={FaLinkedinIn}
                        color="white"
                        boxSize={6}
                        _hover={{ color: "#ebe9e8" }}
                        _active={{ color: "#609ac3" }}
                        transition="0.15s"
                      />
                    }
                    variant="ghost"
                    _hover={{
                      bg: "transparent",
                    }}
                    _active={{ bg: "transparent" }}
                  />
                </Link>
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
