import { Container, Flex, Heading, VStack } from "@chakra-ui/react";
import React from "react";

const ScienceHeader: React.FC = () => {
  return (
    <>
      <Flex w="full" justify="center" align="center" h="40vh">
        <Container maxW="container.xl" centerContent>
          <VStack spacing="5" w="full" justify="center">
            <Heading size="2xl" fontWeight="400" textAlign="center">
              Changing the landscape
            </Heading>
            <Heading size="2xl" fontWeight="400" textAlign="center">
              of drug discovery
            </Heading>
          </VStack>
        </Container>
      </Flex>
    </>
  );
};

export default ScienceHeader;
