import {
  Flex,
  Heading,
  VStack,
  Text,
  Container,
  Box,
  Avatar,
  Image,
  Circle,
  HStack,
  IconButton,
  Icon,
  Link,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { MdEmail } from "react-icons/md";
import { GrLinkedinOption } from "react-icons/gr";

const TeamMembers: React.FC = () => {
  return (
    <>
      <Flex
        direction={{ base: "column", lg: "column" }}
        py="10"
        minH="70vh"
        id="contact"
        pt={{ base: "10", md: "40" }}
        zIndex="10"
        bg="white"
        gap="20"
        justify="center"
        align="center"
      >
        <VStack w="full" spacing="3" px={{ base: "3", md: "0" }}>
          <Heading w="full" textAlign="center">
            Our Management
          </Heading>
          <Text w="full" fontSize="md" textAlign="center">
            Meet our leadership team
          </Text>
        </VStack>
        <Container maxW="container.xl">
          <Flex
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            align="center"
            p="0"
            w="full"
            gap={{ base: "10", lg: "0" }}
          >
            <Flex
              direction="column"
              align="center"
              justify="center"
              gap="4"
              p="0"
              w="full"
            >
              <Image
                borderRadius="full"
                w="170px"
                h="400px"
                alt="Huram Konjen"
                src="/images/team1.jpg"
                border="none"
                objectFit="cover"
              />
              <VStack w="full" spacing="0" justify="center">
                <Text
                  fontSize="lg"
                  fontWeight="600"
                  w="full"
                  textAlign="center"
                >
                  Huram Konjen
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="400"
                  w="full"
                  textAlign="center"
                >
                  Chief Executive Officer
                </Text>
              </VStack>
              <VStack w="full" spacing="0">
                <Text fontSize="sm" w="full" textAlign="center">
                  Led 1st AI driven public hearing in Denmark
                </Text>
                <Text fontSize="sm" w="full" textAlign="center">
                  MSc Digital Innovation & Management
                </Text>
              </VStack>
              <HStack w="full" spacing="6" justify="center">
                <Link href="mailto:huram@dianox.com" isExternal>
                  <IconButton
                    aria-label="Huram Email"
                    icon={<Icon as={MdEmail} />}
                    borderRadius="full"
                  />
                </Link>
                <Link href="https://www.linkedin.com/in/huram/" isExternal>
                  <IconButton
                    aria-label="Huram Linkedin"
                    icon={<Icon as={GrLinkedinOption} />}
                    borderRadius="full"
                  />
                </Link>
              </HStack>
            </Flex>
            <Flex direction="column" align="center" gap="4" w="full">
              <Image
                borderRadius="full"
                w="170px"
                h="400px"
                alt="Christian Code"
                src="/images/team2.jpg"
                border="none"
                objectFit="cover"
              />

              <VStack w="full" spacing="0">
                <Text fontSize="lg" fontWeight="600">
                  Christian Code
                </Text>
                <Text fontSize="md" fontWeight="400">
                  Chief Technology Officer
                </Text>
              </VStack>
              <VStack w="full" spacing="0">
                <Text fontSize="sm">
                  Scaled 3 cancer drugs from clinic to market
                </Text>
                <Text fontSize="sm">PhD Biophysics</Text>
              </VStack>
              <HStack w="full" spacing="6" justify="center">
                <Link href="mailto:chris@dianox.com" isExternal>
                  <IconButton
                    aria-label="Christian Code Email"
                    icon={<Icon as={MdEmail} />}
                    borderRadius="full"
                  />
                </Link>
                <Link
                  href="https://www.linkedin.com/in/christiancode/"
                  isExternal
                >
                  <IconButton
                    aria-label="Christian Code Linkedin"
                    icon={<Icon as={GrLinkedinOption} />}
                    borderRadius="full"
                  />
                </Link>
              </HStack>
            </Flex>
          </Flex>
          <Flex w="full" direction="row" justify="center" mt="16" p="0">
            <Link
              href="https://www.linkedin.com/search/results/people/?currentCompany=%5B%2214416814%22%5D&amp;network=%5B%22F%22%5D&amp;origin=COMPANY_PAGE_CANNED_SEARCH"
              isExternal
            >
              <Button
                colorScheme="blue"
                variant="outline"
                size="lg"
                bg="linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)"
                border="none"
                _hover={{
                  bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  border: "none",
                  filter: "brightness(125%)",
                }}
                _active={{
                  bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                  border: "none",
                  filter: "brightness(75%)",
                }}
                transition="0.15s"
                color="white"
              >
                See our team on Linkedin
              </Button>
            </Link>
          </Flex>
        </Container>
      </Flex>
    </>
  );
};

export default TeamMembers;
