import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import ScienceHeader from "../../components/science/header";
import ScienceCare from "../../components/science/header/care";
import ScienceProcess from "../../components/science/process";
import ScienceUnlock from "../../components/science/unlock";

const Science: React.FC = () => {
  return (
    <>
      <Box position="relative" pb="0" className="parent-container">
        <Flex w="full" direction="column" gap="0" pb="16">
          <NavBar />
          <ScienceHeader />
          <Flex direction="column" gap={{ base: 8, lg: 0 }}>
            <ScienceCare />
            <ScienceUnlock />
            <ScienceProcess />
          </Flex>
        </Flex>

        <Footer />
      </Box>
    </>
  );
};

export default Science;
