import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import Contact from "../../components/home/contact_development"
import CustomDevelopmentLanding from "../../components/custom_development/landing";
import DevelopmentServices from "../../components/custom_development/development_services"
import CustomDevelopmentInfoBanner from "../../components/custom_development/info_banner";


const CustomDevelopmentPage: React.FC = () => {
  return (
    <>
      <Box position="relative" pb="0" className="parent-container">
        <Flex w="full" direction="column" gap="10" pb="16" justify="center">
          <NavBar />
          <CustomDevelopmentLanding />
          <CustomDevelopmentInfoBanner />
          <DevelopmentServices />
          <Contact />
        </Flex>
        <Footer />
      </Box>
    </>
  );
};

export default CustomDevelopmentPage;
