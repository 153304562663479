import {
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

const DanishChamberofCommerce: React.FC = () => {
  return (
    <Flex w="full">
      <Container maxW="container.lg" centerContent>
        <Flex
          w="full"
          direction="column"
          align="center"
          justify="center"
          gap="10"
        >
          <Image src="/images/CEO5-cropped-min.jpg" alt="Innobooster Header" />
          <Heading>Interviewed by Danish Chamber of Commerce</Heading>

          <Divider size="10px" color="black" />
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="10"
            >
              <Text w="full" textAlign="justify">
                Dianox CEO sat down with with the Danish Chamber of Commerce
                (Dansk Erhverv) to discuss entrepreneurship and biotechnology.
              </Text>{" "}
              <Text w="full" textAlign="justify">
                This is a translated excerpt. Original article in Danish:{" "}
                <Link
                  href="https://www.danskerhverv.dk/presse-og-nyheder/nyheder/2020/december/ivarksatterinterview-med-dianox-konkurrenter-gor-os-alle-bedre/"
                  isExternal
                >
                  danskerhverv.dk{" "}
                </Link>
              </Text>
              <Text w="full" textAlign="justify">
                The biotech company Dianox has taken on some of humanity's
                greatest challenges and made it a huge opportunity for the
                benefit of public health. The world may face more pandemic
                threats in the future. While there are several diagnostic tools
                available, most are not scalable. There is therefore an urgent
                need for radical innovation in diagnostics to enable a broad
                screening of populations.
              </Text>{" "}
              <Text w="full" textAlign="justify">
                Dianox is one of the companies in Symbion's office community,
                where startups share everything from coffee machines to
                laboratory equipment. The office community simmers with
                creativity and entrepreneurship: Here there are floating office
                spaces, soft chairs and men in suits who put on the freshly
                polished leather shoes to take a nap on the sofas. In addition,
                the ceiling is high and nature is drawn into large potted
                plants.
              </Text>{" "}
              <Text w="full" textAlign="justify">
                The balance between nature and its future development is
                essential for Dianox. Using deep biotechnology, Huram Konjen can
                call himself the CEO of a company whose product can detect
                biomarkers in saliva. Early detection is the best way to
                strengthen global efforts to eradicate health problems. With the
                development of the new technology, Huram hopes that in the
                future we can promote well-being at all ages.
              </Text>{" "}
              <Text w="full" textAlign="justify">
                The most recently developed tool is the Educational Toolbox, a
                compilation of material that deepens the understanding of the
                biomarker commercialization process and works as a user manual
                accompanying the BIC Guide. Its purpose is to give a quick
                introduction to BIC tools as well as deeper information, for
                example, about diagnostic and statistical measures in the BIC
                Guide, starting up your own company and different issues of
                IVDR.
              </Text>{" "}
            </Flex>
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="0"
            >
              <Image src="/images/CEO11-min.png" />
              <Text w="full" fontSize="xs">
                CEO of Dianox
              </Text>{" "}
            </Flex>
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="10"
            >
              <Text w="full" textAlign="justify">
                The entrepreneurial dream: The desire to make a difference in
                the world For Huram, the entrepreneurial dream started abroad.
                In his volunteer work, he quickly learned that globally there is
                far too little focus on diagnostics: “We are privileged in
                Denmark. When we encounter a challenge, we do not experience the
                problems intimately as some people do in other countries. That
                is why it is crucial that our product is launched and has an
                effect in the world. Major health problems have been an
                overlooked challenge. We vaccinate our way out of health
                challenges in the Nordics, which is why we do not see them in
                our daily lives.”
              </Text>{" "}
              <Text w="full" textAlign="justify">
                In addition to the humanitarian challenge, it is also the
                biotechnological complexity that drives the team behind Dianox:
                “A big drive for us at Dianox is that this is a complex
                challenge. Research is often done without creating a concrete
                product. With our product, we want to transform research data so
                the findings can be utilized and have an effect in the world.”
              </Text>
              <Text w="full" textAlign="justify">
                In other words, it was a burning desire to innovate that drove
                Huram to start Dianox and involve some of Denmark's most
                talented bio researchers: “There is a lot of talent in Denmark.
                We have high quality education in this country and dedicated
                people.”
              </Text>{" "}
              <Text w="full" textAlign="justify">
                And it was necessary to find the most talented people, because
                Huram, who has a background in marketing and innovation,
                discovered that for years professionals in diagnostics simply
                repeated what they used to do, or at most sought to improve
                existing technology gradually: "The incremental improvements
                were ultimately redundant because the measures gained no
                significance or value for the public. The work can be done a
                little faster for the laboratory technician by improving
                existing technology, but the public does not get faster results.
                In other words, it is useless for the the consumer. It is silly,
                because it does not make sense not to be focused on the public.
                It is precisely the public that needs the technology to work as
                fast as possible.”
              </Text>{" "}
            </Flex>
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="0"
            >
              <Image src="/images/Office-min.jpg" />
              <Text w="full" fontSize="xs">
                Dianox HQ
              </Text>{" "}
            </Flex>
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="10"
            >
              <Text w="full" textAlign="justify">
                The root of Dianox's product is to create great value for
                society and human life.
              </Text>{" "}
            </Flex>
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="0"
            >
              <Image src="/images/chris%20lab-min.jpg" />
              <Text w="full" fontSize="xs">
                CTO in the lab
              </Text>{" "}
            </Flex>
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="10"
            >
              <Text w="full" textAlign="justify">
                The root of success was a strong team and competition When
                Dianox saw the light of day in 2018, Huram started by building a
                strong team:
              </Text>{" "}
              <Text w="full" textAlign="justify">
                “The most important experience I have gained is building a
                strong team. There are different principles that you can follow
                as a new entrepreneur, but there is not one universal recipe for
                success. If you have a strong team, then you are well on your
                way. I have a really good team behind me who, like me, wants our
                product to be launched and have a lasting impact. I think it is
                important to have a shared vision. It shapes and strengthens a
                business. In addition, we are very focused on diversity in
                Dianox. Our different backgrounds give us a better product where
                we do not get stuck in the mindset of 'how things are usually
                done'. It ensures that we develop a product that someone
                actually wants to buy.”
              </Text>
              <Text w="full" textAlign="justify">
                In addition to a strong team, competition in the market has been
                a major driving force for Dianox:
              </Text>{" "}
              <Text w="full" textAlign="justify">
                “The competition has been healthy for us. It has strengthened
                our business case to spar with others. I think one of the most
                important things I learned is that it can be helpful when more
                people chase the same thing, because someone will have proven
                the value of the business case. It provides a better opportunity
                for investment to be able to point to other success stories
                within your own industry. In other words, we have positive
                experience of meeting competing companies, and especially those
                who were not successful. By examining why they did not succeed,
                we are already one step closer to our goal. I do not think we
                should be so afraid of competition. Competition makes us run
                faster.”
              </Text>{" "}
            </Flex>
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="0"
            >
              <Image src="/images/CTO2-min.jpg" />
              <Text w="full" fontSize="xs">
                Lab team of Dianox
              </Text>{" "}
            </Flex>
            <Container
              maxW="container.md"
              borderLeft="2px solid"
              borderColor="cyan.500"
              my="20"
              h="200px"
            >
              <Flex
                w="full"
                direction="column"
                align="center"
                h="full"
                justify="center"
                gap="6"
                pl="8"
                position="relative"
              >
                <Image
                  src="/images/quotes.png"
                  position="absolute"
                  top="50"
                  left="0"
                />
                <Text
                  w="full"
                  textAlign="justify"
                  fontSize="xl"
                  fontWeight="600"
                >
                  There has be space to dream big.
                </Text>
              </Flex>
            </Container>
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="10"
            >
              <Text w="full" textAlign="justify">
                Despite the fact that Huram's idea was conceived on the
                international stage, he is happy that he came home to start up
                in Denmark. He mentions how crucial it is to get inspiration
                from abroad: “We have a passport that can do everything. My
                advice to new entrepreneurs: Use it! Travel and get inspired.”
                That statement prompts the question: "Why did you start the
                company in Denmark?" To this, Dianox's CEO responds without
                hesitation: "I could not have imagined doing it elsewhere. But
                it gave me a clear advantage to have spent time abroad to get
                inspiration. In Denmark, we are influenced by the Law of Jante,
                which is a Scandinavian cultural marker that dictates how people
                feel about standing out from the crowd. I think that inhibits
                the startup culture in Denmark. As an entrepreneur, you have to
                be willing to take huge risk, solely because you yourself
                believe in your idea and your abilities. Unfortunately, it is my
                experience that great ambitions are often met with critical
                questions about whether it is realistic to dream that big here
                in Denmark. It does not make any sense to downplay the ambition
                of entrepreneurs. If we do, we will get nowhere. There has be
                space to dream big. We did.”
              </Text>{" "}
            </Flex>
          </Container>
        </Flex>
      </Container>
    </Flex>
  );
};

export default DanishChamberofCommerce;
