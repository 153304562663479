import {
  Container,
  Flex,
  GridItem,
  SimpleGrid,
  Image,
  Text,
  Button,
  Box,
} from "@chakra-ui/react";
import React from "react";

const LibraryAccess: React.FC = () => {
  return (
    <>
      <Flex
        w="full"
        justify="center"
        align="center"
        h={{ base: "auto", lg: "auto" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 0, md: 2 }}>
              <Flex justify="center" align="center">
                <Image src="/images/apt2.png" alt="2d-rna-aptamer" />
              </Flex>

            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 1, md: 1 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="2xl" fontWeight="600">
                  How to access our library
                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    Whether you are an academic researcher, physician, or biotechnology scientist, our library is ready to serve as your dependable source for quality aptamers. Our team is ready to help you.
                    Our library contains in vitro validated aptamers against thousands of protein targets. If you are interested in accessing the library, we have a simple 4 step process:
                  </Text>
                </Box>
              </Flex>
              <Flex
                direction={{ base: "column", md: "row" }}
                gap="6"
                justifyContent="flex-start"
                mt="10"
                px={{ base: "12", md: 0 }}
              >
                <Button
                  as="a"
                  href="mailto:order@dianox.com?Subject=Aptamer%20library"
                  colorScheme="blue"
                  variant="outline"
                  size="lg"
                  bg="linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)"
                  border="none"
                  _hover={{
                    bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                    border: "none",
                    filter: "brightness(125%)",
                  }}
                  _active={{
                    bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                    border: "none",
                    filter: "brightness(75%)",
                  }}
                  transition="0.15s"
                  color="white"
                >
                  Talk to aptamer scientist
                </Button>
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
      <Flex
        direction="column"
        py="10"
        minH="50vh"
        id="service"
        pt="40"
        zIndex="10"
        bg="white"
        gap="20"
        maxW="100vw"
        overflowX="hidden"
      >
        <Container
          maxW="container.xl"
          overflowX={{ base: "scroll", md: "hidden" }}
        // bgColor="blue"
        >
          <Flex direction="row" py="10">
            <Flex
              position="relative"
              pl="4"
              py="4"
              pr="4"
              direction="column"
              boxShadow="lg"
              flexGrow="1"
              minW="250px"
              m="5"
              mb="0"
              bgPosition="left 10px top 20px"
              bgRepeat="no-repeat"
              bgSize="auto 150px"
              borderRadius="16px"
              _hover={{
                bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                border: "none",
                filter: "brightness(125%)",
                color: "white"

              }}
              _active={{
                bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                border: "none",
                filter: "brightness(75%)",
                color: "white"
              }}
              transition="0.15s"
            >
              <Box className="arrow" position="absolute" top="16" right="-6">
                <Image src="/images/arrow.png" />
              </Box>
              <Flex direction="column" zIndex="10">
                <Text fontSize="xl" fontWeight="600" w="full" textAlign="left">
                  Browse our library
                </Text>
                <Text fontSize="sm">
                  Our process is simple and starts with you sending your targets to us.
                  Your targets will be cross-referenced with our library,
                  returning a shortlist of available targets.
                </Text>
              </Flex>
            </Flex>
            <Flex
              position="relative"
              pl="4"
              py="4"
              pr="4"
              direction="column"
              boxShadow="lg"
              flexGrow="1"
              minW="250px"
              m="5"
              mb="0"
              bgPosition="left 10px top 20px"
              bgRepeat="no-repeat"
              bgSize="auto 150px"
              borderRadius="16px"
              _hover={{
                bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                border: "none",
                filter: "brightness(125%)",
                color: "white"

              }}
              _active={{
                bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                border: "none",
                filter: "brightness(75%)",
                color: "white"
              }}
              transition="0.15s"
            >
              <Box className="arrow" position="absolute" top="16" right="-6">
                <Image src="/images/arrow.png" />
              </Box>
              <Flex direction="column" zIndex="10">
                <Text fontSize="xl" fontWeight="600" w="full" textAlign="left">
                  Make your selection
                </Text>
                <Text fontSize="sm">
                  Select your desired targets from the shortlist of available targets.
                </Text>
              </Flex>
            </Flex>
            <Flex
              position="relative"
              pl="4"
              py="4"
              pr="4"
              direction="column"
              boxShadow="lg"
              flexGrow="1"
              minW="250px"
              m="5"
              mb="0"
              bgPosition="left 10px top 20px"
              bgRepeat="no-repeat"
              bgSize="auto 150px"
              borderRadius="16px"
              _hover={{
                bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                border: "none",
                filter: "brightness(125%)",
                color: "white"
              }}
              _active={{
                bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                border: "none",
                filter: "brightness(75%)",
                color: "white"
              }}
              transition="0.15s"
            >
              <Box className="arrow" position="absolute" top="16" right="-6">
                <Image src="/images/arrow.png" />
              </Box>
              <Flex direction="column" zIndex="10">
                <Text fontSize="xl" fontWeight="600" w="full" textAlign="left">
                  Place order
                </Text>
                <Text fontSize="sm">
                  A payment link will be sent to you for €4,500 per target.
                </Text>
              </Flex>
            </Flex>
            <Flex
              position="relative"
              pl="4"
              py="4"
              pr="4"
              direction="column"
              boxShadow="lg"
              flexGrow="1"
              minW="250px"
              m="5"
              mb="0"
              bgPosition="left 10px top 20px"
              bgRepeat="no-repeat"
              bgSize="auto 150px"
              borderRadius="16px"
              _hover={{
                bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                border: "none",
                filter: "brightness(125%)",
                color: "white"

              }}
              _active={{
                bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                border: "none",
                filter: "brightness(75%)",
                color: "white"
              }}
              transition="0.15s"
            >
              <Flex direction="column" zIndex="10">
                <Text fontSize="xl" fontWeight="600" w="full" textAlign="left">
                  Delivery
                </Text>
                <Text fontSize="sm">
                  Once payment has been confirmed, you will receive one aptamer sequence against each selected target.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Container>
        <Flex
          direction={{ base: "column", md: "row" }}
          gap="6"
          justifyContent="center"
          mt="10"
          px={{ base: "12", md: 0 }}
        >
          <Button
            colorScheme="blue"
            variant="outline"
            size="lg"
            onClick={() => {
              document.getElementById("contact")!.scrollIntoView();
            }}
            bg="linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)"
            border="none"
            _hover={{
              bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
              border: "none",
              filter: "brightness(125%)",
            }}
            _active={{
              bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
              border: "none",
              filter: "brightness(75%)",
            }}
            transition="0.15s"
            color="white"
          >
            Request aptamer sequence
          </Button>
        </Flex>
      </Flex >

    </>
  );
};

export default LibraryAccess;
