import React from "react";
import {
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

const EUFund: React.FC = () => {
  return (
    <Flex w="full">
      <Container maxW="container.lg" centerContent>
        <Flex
          w="full"
          direction="column"
          align="center"
          justify="center"
          gap="10"
        >
          <Image
            src="/images/shutterstock_347668076-croped.jpg"
            alt="Innobooster Header"
          />
          <Heading>€45,000 awarded by EU Fund</Heading>
          <Divider size="10px" color="black" />
          <Heading size="md">
            Support from Discovery to Clinic: The Complete BIC Biomarker
            Commercialization Toolset Now Available
          </Heading>
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="10"
            >
              <Text w="full" textAlign="justify">
                By the end of September 2021, the four-year long journey of the
                BIC Project has reached its final endpoint. It is culminating in
                the launch of the most recently developed biomarker
                commercialization tools, which together with the already
                available tools form a complete support for successful
                translation of in-vitro diagnostic biomarker inventions into
                clinical applications.
              </Text>{" "}
              <Text w="full" textAlign="justify">
                Biomarkers have the potential to revolutionise the medical
                field. The digital revolution offers unprecedented possibilities
                for personalized medicine. Yet, the gap between biomarker
                discoveries and the market uptake of these innovations is
                significant.
              </Text>
              <Text w="full" textAlign="justify">
                “The challenge this field is facing is to involve industry, such
                as pharmaceutical and diagnostic enterprises, SMEs and
                investors, much earlier in the development and commercialization
                process of biomarkers, while research institutions need guidance
                to select the most relevant biomarker discoveries and conduct a
                development plan that meets early requirements from relevant
                industry partners,” says Valerie Daussin Laurent from the
                innovation department of the University Hospital of Aalborg in
                Denmark, and project leader of the BIC BRIDGE consortium.
              </Text>
              <Text w="full" textAlign="justify">
                The BIC Project is now reaching its goal to enable better and
                more efficient commercialization of biomarker discoveries, as
                the development of the platform that provides new tools to
                support the different phases of a new commercialization process
                is completed.
              </Text>
            </Flex>
          </Container>
          <Container maxW="container.lg" centerContent>
            <Heading size="md">
              BIC Biomarker Commercialization Tools – Support from Discovery to
              Market
            </Heading>
          </Container>
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="6"
            >
              <Text w="full" textAlign="justify">
                All tools are freely available on the biomarker.nu website and
                offer support from discovery to placing the innovation on the
                market. The main tool is the BIC Biomarker Commercialization
                Guide, which leads the user through the technology readiness
                levels (TRL), highlighting the technical, commercial and
                regulatory requirements of each phase to achieve the best
                outcome. The other tools are supportive “add-ons” that
                supplement the BIC Guide and offer a deeper insight to certain
                aspects of the development process.
              </Text>{" "}
              <Text w="full" textAlign="justify">
                The most recently developed tool is the Educational Toolbox, a
                compilation of material that deepens the understanding of the
                biomarker commercialization process and works as a user manual
                accompanying the BIC Guide. Its purpose is to give a quick
                introduction to BIC tools as well as deeper information, for
                example, about diagnostic and statistical measures in the BIC
                Guide, starting up your own company and different issues of
                IVDR.
              </Text>
              <Text w="full" textAlign="justify">
                Pauli Ollikka, Project Coordinator of Development Services at
                University of Turku, Finland, is confident that people will
                benefit from this tool: “In my opinion, the Educational Toolbox
                is meant for all: for those who are wondering if the BIC tools
                are something that they can benefit from and for those who are
                already using the BIC tools but want further information.” Other
                BIC biomarker commercialization tools include already
                well-established BIC instruments like the BIC Review Tool, but
                also several new tools such as the project management platform
                to make the BIC Guide more interactive and easier to use. “The
                now-static BIC Guide will be aligned with an open-source
                project-management platform, which will make the tool highly
                useful for biomarker development projects from invention to a
                finalized product and beyond,” explains Ewa Zdunczyk, Project
                Manager at Wroclaw Technology Park.
              </Text>
            </Flex>
          </Container>
          <Container maxW="container.lg" centerContent>
            <Heading size="md">
              BIC In-Vitro Diagnostics Regulatory Tools: IVDR Guide and IVDR
              Roadmap
            </Heading>
          </Container>
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="6"
            >
              <Text w="full" textAlign="justify">
                In-vitro diagnostics is becoming increasingly regulated and on
                that note the BIC consortium developed two separate, yet
                complementary tools. The BIC IVDR Guide is written from the
                scientific perspective and tells what is required during the
                commercialization process, focusing on the so-called TTO
                Commercialization Model. The IVDR Roadmap is more focused on
                later stages of the development and is written from the
                manufacturer’s perspective. It is a map that provides guidance
                not only on what to do, but also on how to do it.
              </Text>{" "}
              <Text w="full" textAlign="justify">
                “The manufacturer is faced with a set of regulations and
                standards around the IVDs. In the IVDR Roadmap, the regulatory
                activities are aligned with the standards that provide the
                solution to achieving regulatory compliance,” clarifies Pawel
                Myszczyński, the main author of the BIC Regulatory Tools and a
                R&D Project Specialist at Wroclaw Technology Park in Poland.
              </Text>
            </Flex>
          </Container>{" "}
          <Container maxW="container.lg" centerContent>
            <Heading size="md">Where do we go from here?</Heading>
          </Container>
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="6"
            >
              <Text w="full" textAlign="justify">
                Although the BIC project is reaching its end, the consortium
                partners continue on their path to bring biomarker discoveries
                closer to the patients.
              </Text>{" "}
              <Text w="full" textAlign="justify">
                “Since we started to have results to share, we have focused on
                building a larger network of people sharing our interest and
                knowledge. We want to build into a larger European initiative,
                where we currently look at the possibility to start a biomarker
                accelerator with access to funding. We are also looking at new
                challenges, where the digital part of diagnosing with biomarkers
                are clearly one of our interests. It is still very early, but if
                someone is interested in joining, do get in contact!”, says
                Valerie Daussin Laurent. (contracts available via our website)
              </Text>
            </Flex>
          </Container>
          <Image src="/images/bic_bridge-min.png" alt="Image" />
          <Text fontSize="xs">Partners in the BIC BRIDGE consortium</Text>
          <Container maxW="container.lg" centerContent>
            <Heading size="md">About the BIC BRIDGE Consortium</Heading>
          </Container>
          <Container maxW="container.md">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              gap="6"
            >
              <Text w="full" textAlign="justify">
                BIC BRIDGE is based on the project main outputs and results from
                BiC – Biomarker Commercialization and serves not only to improve
                the outputs delivered in the original project but also to a
                whole new set of activities aiming at increasing the scope of
                the tools and broadening the target groups, as well as
                increasing usability, sustainability and impact. The consortium
                comprises 9 partners from the Baltic Sea Region (BSR) and
                beyond. It consists of innovation units localized in research
                institutions, cluster organisations, SMEs and European Research
                Infrastructure Consortium within the health diagnostic, pharma
                and med tech field. The extension stage project is running for
                12 months. The BIC BRIDGE project’s budget is EUR 0.76 million
                and is co-financed by the European Regional Development Fund
                through the Interreg Baltic Sea Region Programme with EUR 0.58
                million. Find out more on biomarker.nu.
              </Text>{" "}
            </Flex>
          </Container>
        </Flex>
      </Container>
    </Flex>
  );
};

export default EUFund;
