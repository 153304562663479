import { Flex, Container, Box, VStack, Text, Heading } from "@chakra-ui/react";
import React from "react";

const LibraryInfoBanner: React.FC = () => {
  return (
    <Box
      w="full"
      h="full"
      bgGradient="linear-gradient(98.65deg,#00535B 7.37%,#00C4D1 98.43%)"
      py="8"
    >
      <Container maxW="container.xl">
        <Flex
          direction={{ base: "column", md: "row" }}
          gap="0"
          justifyContent="space-between"
          align="center"
        >
          <VStack spacing="1">
            <Heading w="full" textAlign="center" color="white">
              Library
            </Heading>
            <Text w="full" fontSize="md" textAlign="center" color="white">
              in vitro validated aptamers
            </Text>
          </VStack>
          <VStack spacing="1">
            <Heading w="full" textAlign="center" color="white">
              Targeted
            </Heading>
            <Text w="full" fontSize="md" textAlign="center" color="white">
              bind to specific proteins or small molecules
            </Text>
          </VStack>
          <VStack spacing="1">
            <Heading w="full" textAlign="center" color="white">
              €4,500
            </Heading>
            <Text w="full" fontSize="md" textAlign="center" color="white">
              per target
            </Text>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default LibraryInfoBanner;
