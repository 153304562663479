import { Box, chakra, Flex, Heading } from "@chakra-ui/react";
import React from "react";

const InvestHeader: React.FC = () => {
  return (
    <>
      <Flex
        h="40vh"
        w="full"
        bgImage="url('https://images.pexels.com/photos/10670941/pexels-photo-10670941.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')"
        bgSize="contain"
        bgPosition="top"
      >
        <Flex
          h="40vh"
          w="full"
          justify="center"
          alignItems="center"
          bg="rgba(0,0,0,0.5)"
        >
          <Heading
            fontWeight="600"
            textAlign={{ base: "center", md: "center" }}
            color="white"
            size={{ base: "xl", md: "2xl" }}
            w="full"
            bg="transparent"
          >
            Why Invest
          </Heading>
        </Flex>
      </Flex>
    </>
  );
};

export default InvestHeader;
