import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import Contact from "../../components/home/contact_development"
import EventsLanding from "../../components/events/landing";


const EventsPage: React.FC = () => {
  return (
    <>
      <Box position="relative" pb="0" className="parent-container">
        <Flex w="full" direction="column" gap="10" pb="16" justify="center">
          <NavBar />
          <EventsLanding />
          <Contact />
        </Flex>
        <Footer />
      </Box>
    </>
  );
};

export default EventsPage;
