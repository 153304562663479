import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import UniDiscount from "../../components/uni_discount/discount"
import Contact from "../../components/uni_discount/contact_discount"

const UniDiscountPage: React.FC = () => {
  return (
    <>
      <Box position="relative" pb="0" className="parent-container">
        <Flex w="full" direction="column" gap="0" pb="16">
          <NavBar />
          <UniDiscount />
          <Contact />
        </Flex>
        <Footer />
      </Box>
    </>
  );
};

export default UniDiscountPage;
