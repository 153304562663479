import {
  Container,
  Flex,
  GridItem,
  SimpleGrid,
  Image,
  Text,
  Button,
  Box,
} from "@chakra-ui/react";
import React from "react";

const MoleculeAreas: React.FC = () => {
  return (
    <>
      <Flex
        w="full"

        justify="center"
        align="center"
        h={{ base: "auto", lg: "auto" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 1, md: 1 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="2xl" fontWeight="600">
                  Target your molecule with aptamers
                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    Our library has an impressive selection of aptamers designed for proteins and small molecules. This diverse collection covers a wide range of targets, including but not limited to:
                  </Text>
                </Box>
              </Flex>
              <Flex
                direction={{ base: "column", md: "row" }}
                gap="6"
                justifyContent="flex-start"
                mt="10"
                px={{ base: "12", md: 0 }}
              >
                <Button
                  colorScheme="blue"
                  variant="outline"
                  size="lg"
                  onClick={() => {
                    document.getElementById("contact")!.scrollIntoView();
                  }}
                  bg="linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)"
                  border="none"
                  _hover={{
                    bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                    border: "none",
                    filter: "brightness(125%)",
                  }}
                  _active={{
                    bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                    border: "none",
                    filter: "brightness(75%)",
                  }}
                  transition="0.15s"
                  color="white"
                >
                  Request aptamer sequence
                </Button>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 0, md: 2 }}>
              <Flex justify="center" align="center">
                <Image src="/images/apt_2d.png" alt="2d-rna-aptamer" />
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
      <Flex
        w="full"

        justify="center"
        align="center"
        h={{ base: "auto", lg: "auto" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 0, md: 2 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="xl" fontWeight="600">
                  Enzymes
                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    We provide aptamers that can recognize and bind to various enzymes with high specificity, useful in studying enzyme mechanisms, regulation, and inhibition.
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 1, md: 1 }}>
              <Flex justify="center" align="center">
                <Image src="/images/enzyme.svg" alt="2d-rna-aptamer" />
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
      <Flex
        w="full"

        justify="center"
        align="center"
        h={{ base: "auto", lg: "auto" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 1, md: 1 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="xl" fontWeight="600">
                  Cell surface proteins
                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    Our aptamers can recognize a variety of cell surface proteins, aiding in cell sorting, cell signaling studies, and potential therapeutic applications.
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 0, md: 2 }}>
              <Flex justify="center" align="center">
                <Image src="/images/surface_protein.svg" alt="cell-surface-protein" />
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
      <Flex
        w="full"

        justify="center"
        align="center"
        h={{ base: "auto", lg: "auto" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 0, md: 2 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="xl" fontWeight="600">
                  Intracellular proteins
                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    Our aptamers offer a means to study intracellular processes, including protein-protein interactions and cellular pathways.
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 1, md: 1 }}>
              <Flex justify="center" align="center">
                <Image src="/images/intracellular_protein.svg" alt="intracellular-protein" />
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
      <Flex
        w="full"

        justify="center"
        align="center"
        h={{ base: "auto", lg: "auto" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 1, md: 1 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="xl" fontWeight="600">
                  Disease-associated proteins
                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    We have a selection of aptamers for proteins associated with diseases, including cancer, neurodegenerative diseases, cardiovascular disorders and countless more. These aptamers may serve as tools for diagnostics, therapeutics, and research into these conditions.
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 0, md: 2 }}>
              <Flex justify="center" align="center">
                <Image src="/images/virus.svg" alt="virus" />
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
      <Flex
        w="full"

        justify="center"
        align="center"
        h={{ base: "auto", lg: "auto" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 0, md: 2 }}>
              <Flex direction="column" align="center" justify="center">
                <Text w="full" textAlign="left" fontSize="xl" fontWeight="600">
                  Immunoglobulins                </Text>
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    Our library houses aptamers that bind to immunoglobulins, useful in immunology research, diagnostic assay development, and potential therapeutic strategies.
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 1, md: 1 }}>
              <Flex justify="center" align="center">
                <Image src="/images/ig.svg" alt="2d-rna-aptamer" />
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
      <Flex
        w="full"

        justify="center"
        align="center"
        h={{ base: "auto", lg: "auto" }}
      >
        <Container maxW="container.xl" centerContent>
          <SimpleGrid columns={2} spacing="4">
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 1, md: 1 }}>
              <Flex direction="column" align="center" justify="center">
                <Box mt="6">
                  <Text lineHeight="10" w="full">
                    This is just a glimpse of the breadth and diversity of our protein-targeting aptamers. If you require an aptamer for a specific protein target, please reach out to us. We also offer custom aptamer development services to cater to your unique needs.
                  </Text>
                </Box>
              </Flex>
              <Flex
                direction={{ base: "column", md: "row" }}
                gap="6"
                justifyContent="flex-start"
                mt="10"
                px={{ base: "12", md: 0 }}
              >
                <Button
                  colorScheme="blue"
                  variant="outline"
                  size="lg"
                  onClick={() => {
                    document.getElementById("contact")!.scrollIntoView();
                  }}
                  bg="linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)"
                  border="none"
                  _hover={{
                    bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                    border: "none",
                    filter: "brightness(125%)",
                  }}
                  _active={{
                    bg: "linear-gradient(98.65deg,#76449F 7.37%,#489FDA 98.43%)",
                    border: "none",
                    filter: "brightness(75%)",
                  }}
                  transition="0.15s"
                  color="white"
                >
                  Request aptamer sequence
                </Button>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }} order={{ base: 0, md: 2 }}>
              <Flex justify="center" align="center">
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
    </>
  );
};

export default MoleculeAreas;
